import { useState } from 'react';

import { InviteUserDTO } from 'dto';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';
import { UserService } from 'services';

/**
 * The initial values that are used by the form
 */
const initialValues: InviteUserDTO = {
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  photoPercentage: 0,
  videoPercentage: 0,
  vatPercentage: 21,
  isBusiness: false,
  sendingHelpPercentage: 0,
  bulkPercentage: 0,
  isThirdParty: false,
  isEditor: false,
  vat: '',
  coc: '',
  iban: '',
  businessName: '',
  street: '',
  housenumber: '',
  zipcode: '',
  place: '',
};

/**
 * A hook that makes it easier to interact with the invite user form
 *
 * @returns The `useInviteUserForm` hook
 */
export function useInviteUserForm(): {
  formValues: InviteUserDTO;
  handleInputChange: (event: any) => void;
  handleSubmit: (event: any) => void;
  submitting: boolean;
} {
  const api = useApi();
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<InviteUserDTO>({
    ...initialValues,
  });

  /**
   * A function that validates the form
   */
  const validate = (values: InviteUserDTO) => {
    const validated = { ...values };
    validated.photoPercentage = parseInt(
      validated.photoPercentage.toString(),
      10,
    );
    validated.videoPercentage = parseInt(
      validated.videoPercentage.toString(),
      10,
    );
    validated.vatPercentage = parseInt(validated.vatPercentage.toString(), 10);
    validated.bulkPercentage = parseInt(
      validated.bulkPercentage.toString(),
      10,
    );
    validated.sendingHelpPercentage = parseInt(
      validated.sendingHelpPercentage.toString(),
      10,
    );
    if (validated.photoPercentage > 100) validated.photoPercentage = 100;
    if (validated.photoPercentage < 0) validated.photoPercentage = 0;
    if (validated.videoPercentage > 100) validated.videoPercentage = 100;
    if (validated.videoPercentage < 0) validated.videoPercentage = 0;
    if (validated.vatPercentage > 100) validated.vatPercentage = 100;
    if (validated.vatPercentage < 0) validated.vatPercentage = 0;
    if (validated.bulkPercentage > 100) validated.bulkPercentage = 100;
    if (validated.bulkPercentage < 0) validated.bulkPercentage = 0;
    if (validated.sendingHelpPercentage > 100)
      validated.sendingHelpPercentage = 100;
    if (validated.sendingHelpPercentage < 0)
      validated.sendingHelpPercentage = 0;
    return validated;
  };

  /**
   * A function that handles the change of input
   * @param event The event to use
   */
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) =>
      validate({ ...prevFormValues, [name]: value }),
    );
  };

  /**
   * A function that submits the form
   * @param event The event to use
   */
  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    setSubmitting(true);
    try {
      const response = await UserService.inviteUser(api, formValues);

      if (response?.success) {
        dispatch(
          emitFeedback({
            type: FeedbackType.OK,
            message: `${formValues.firstName} is succesvol uitgenodigd!`,
          }),
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return { formValues, handleInputChange, handleSubmit, submitting };
}
