import { ReactElement } from 'react';

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import clsx from 'classnames';
import { NavLink } from 'react-router-dom';

import useStyles from './Sidebar.style';

import { hasAccess } from 'features';
import { useAppSelector } from 'hooks';
import { DisplayType, getRoutes } from 'router';

/**
 * A component that handles user navigation at the side of the screen
 *
 * @returns The `Sidebar` component
 */
export function Sidebar(): ReactElement {
  const styles = useStyles();

  const { sidebarOpen } = useAppSelector((state) => state.app);
  const { session } = useAppSelector((state) => state.auth);

  return (
    <Drawer
      variant="permanent"
      className={clsx(styles.drawer, {
        [styles.drawerOpen]: sidebarOpen,
        [styles.drawerClosed]: !sidebarOpen,
      })}
      classes={{
        paper: clsx(styles.drawerPaper, {
          [styles.drawerOpen]: sidebarOpen,
          [styles.drawerClosed]: !sidebarOpen,
        }),
      }}
    >
      <Toolbar />
      <List>
        {getRoutes().map((route) => {
          const access = hasAccess(route.access, session?.token);

          if (access && route.display === DisplayType.SIDEBAR)
            return (
              <ListItem
                exact={route.path === '/'}
                activeClassName={styles.navItemActive}
                button
                className={styles.navItem}
                component={NavLink}
                to={route.path}
                key={route.path}
              >
                <ListItemIcon className={styles.navItemIcon}>
                  {route.icon}
                </ListItemIcon>
                <ListItemText className={styles.navItemText}>
                  {route.name}
                </ListItemText>
              </ListItem>
            );
          return null;
        })}
      </List>
    </Drawer>
  );
}
