import { ReactElement, useState, useEffect } from 'react';

import {
  Box,
  TableCell,
  TableRow,
  Button,
  IconButton,
} from '@material-ui/core';
import {
  CheckCircle as EnableIcon,
  Block as DeleteIcon,
  EditRounded as EditIcon,
  NotInterestedRounded as InactiveIcon,
  PersonAddRounded as AddIcon,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

import { PageTitle, AsyncTable } from 'components';
import { ReadUserDTO } from 'dto';
import { useApi } from 'hooks';
import { UserService } from 'services';

/**
 * A view that allows user management
 *
 * @returns The `ListUsers` view
 */
export function ListUsers(): ReactElement {
  const api = useApi();

  const [userList, setUserList] = useState<ReadUserDTO[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  /**
   * A function that updates the users
   */
  const updateUsers = async () => {
    try {
      setIsLoaded(false);
      setIsError(false);

      const response = await api.get('/user');
      setUserList(response.data.data);
      setIsLoaded(true);
    } catch {
      setIsError(true);
    }
  };

  /**
   * A hook that loads the data on page load
   */
  useEffect(() => {
    updateUsers();
  }, []);

  /**
   * A function that deletes a user based on id
   *
   * @param userId The `id` of the user to delete
   */
  const handleDelete = async (userId: number) => {
    const selection = confirm(
      'Weet u zeker dat u deze gebruiker wilt deactiveren?',
    );
    if (selection === true) {
      await UserService.deleteUser(api, userId);
      updateUsers();
    }
  };

  const handleEnable = async (userId: number) => {
    const selection = confirm(
      'Weet u zeker dat u deze gebruiker weer wilt activeren?',
    );
    if (selection === true) {
      await UserService.activateUser(api, userId);
      updateUsers();
    }
  };

  return (
    <>
      <PageTitle category="Gebruikers" subcategory="Overzicht" />
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <NavLink to="/gebruikers/nieuw">
          <Button variant="contained" color="primary" size="large">
            Gebruiker uitnodigen <AddIcon />
          </Button>
        </NavLink>
      </Box>
      <Box minHeight={400}>
        <AsyncTable
          columns={[
            '#',
            'Naam',
            'Emailadres',
            'Percentage (Foto)',
            'Percentage (Video)',
            'Aangemaakt',
            'Acties',
          ]}
          loading={!isLoaded && !isError}
          error={!!isError}
          empty={!!isLoaded && userList.length < 1}
          striped
        >
          {userList.map((user) => (
            <TableRow key={user.id} style={{ opacity: user.offline ? 0.5 : 1 }}>
              <TableCell>
                {user.id}
                {user.offline && (
                  <IconButton disabled>
                    <InactiveIcon color="action" />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>{`${user.firstName}${
                user.middleName ? user.middleName : ''
              } ${user.lastName}`}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.photoPercentage}%</TableCell>
              <TableCell>{user.videoPercentage}%</TableCell>
              <TableCell>
                {dayjs(user.created).format('D MMMM, YYYY [om] HH:mm')}
              </TableCell>
              <TableCell>
                {!user.offline && (
                  <NavLink to={`/gebruikers/bewerken/${user.id}`}>
                    <IconButton size="small" disabled={user.offline}>
                      <EditIcon color="action" />
                    </IconButton>
                  </NavLink>
                )}
                {user.offline ? (
                  <IconButton
                    size="small"
                    onClick={() => handleEnable(user.id)}
                  >
                    <EnableIcon color="primary" />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    onClick={() => handleDelete(user.id)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </AsyncTable>
      </Box>
    </>
  );
}
