import { AxiosInstance } from 'axios';

import { EditUserDTO, InviteUserDTO, ReadUserDTO } from 'dto';
import { ApiResponse } from 'hooks/useApi';

export class UserService {
  public static async fetchUserList(
    api: AxiosInstance,
  ): Promise<ReadUserDTO[]> {
    const response = await api.get('/user');
    return response.data;
  }

  public static async inviteUser(
    api: AxiosInstance,
    body: InviteUserDTO,
  ): Promise<ApiResponse> {
    const response = await api.post('/auth/invite', body);
    return response.data;
  }

  public static async editUserAdmin(
    api: AxiosInstance,
    userId: number,
    body: EditUserDTO,
  ): Promise<ApiResponse> {
    const response = await api.put(
      `/user/admin/account/${userId}`,
      JSON.stringify(body),
    );
    return response.data;
  }

  public static async deleteUser(
    api: AxiosInstance,
    userId: number,
  ): Promise<ApiResponse> {
    const response = await api.delete(`/user/${userId}`);
    return response?.data;
  }

  public static async activateUser(
    api: AxiosInstance,
    userId: number,
  ): Promise<ApiResponse> {
    const response = await api.patch(`/user/enable/${userId}`);
    return response?.data;
  }
}
