import { useDispatch } from 'react-redux';

import { AppDispatch } from 'store';

/**
 * A hook that provides access to the application dispatch
 *
 * @returns The `useAppDispatch` hook
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();
