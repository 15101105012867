import { ReactElement, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { CloudDownloadRounded as DownloadIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useLocation } from 'react-router-dom';

import { Loader, PageTitle } from 'components';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';

export function ViewExcelExport(): ReactElement {
  const api = useApi();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const queryParams = new URLSearchParams(location.search);

  const [excelData, setExcelData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * A memoized value which converts the dates string into a date object
   */
  const dates = useMemo(
    () => ({
      start: dayjs(queryParams.get('startDate')),
      end: dayjs(queryParams.get('endDate')),
    }),
    [queryParams],
  );

  /**
   * A function that handles the download
   * @param customerId The id of the customer to download
   */
  const handleDownload = async (
    customerId: number,
    customerDescription: string,
  ) => {
    const response = await api.get(
      `/excel?startDate=${queryParams.get(
        'startDate',
      )}&endDate=${queryParams.get('endDate')}&customerId=${customerId}`,
      { responseType: 'blob' },
    );
    try {
      const { data } = response;
      const fileName = `${customerDescription.replace(
        /[^a-zA-Z0-9]/gi,
        '-',
      )}_${dates.start.format('D-M-YYYY')}-tm-${dates.end.format(
        'D-M-YYYY',
      )}.xlsx`;
      saveAs(data, fileName);
    } catch {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message:
            'Er is een fout opgetreden tijdens het downloaden van het document.',
        }),
      );
    }
  };

  /**
   * A function that fetches the excel data
   */
  const fetchExcelData = async () => {
    setIsLoading(true);
    const response = await api.get(
      `/excel/available?startDate=${queryParams.get(
        'startDate',
      )}&endDate=${queryParams.get('endDate')}`,
    );
    setExcelData(response.data.data);
    setIsLoading(false);
  };

  /**
   * A hook that handles the data fetching
   */
  useEffect(() => {
    fetchExcelData();
    return () => {
      setExcelData([]);
    };
  }, []);

  /**
   * A component that handles the listing of downloadable files
   */
  const downloadableItems = excelData.map((excelItem) => (
    <Grid item xs={12} key={excelItem.id}>
      <Paper variant="outlined">
        <Box
          p={1}
          pl={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
        >
          <Typography variant="h6">{excelItem.description}</Typography>
          <IconButton
            color="primary"
            onClick={() => handleDownload(excelItem.id, excelItem.description)}
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Paper>
    </Grid>
  ));

  return (
    <>
      <PageTitle
        category="Export"
        subcategory="Download excel"
        subtitle={`
          Verkopen van
          ${dates.start.format('D MMMM YYYY')}
          tot en met
          ${dates.end.format('D MMMM YYYY')}
        `}
        cancelLink="/export"
      />
      <Container maxWidth="sm">
        <Paper>
          <Box p={2}>
            <Grid container spacing={2}>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {excelData.length > 0 ? (
                    downloadableItems
                  ) : (
                    <Box width="100%" p={1} my={2}>
                      <Alert severity="error">
                        Er zijn geen gegevens beschikbaar...
                      </Alert>
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
