import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Loader, LogoTitle, PageTitle } from 'components';
import { emitFeedback, FeedbackType, updateName } from 'features';
import { useApi, useAppDispatch, useAppSelector } from 'hooks';
import { AuthService } from 'services';

const initialFormValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  vat: '',
  coc: '',
  iban: '',
  businessName: '',
  street: '',
  housenumber: '',
  zipcode: '',
  place: '',
};

const initialAuthFormValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordRepeat: '',
};

export function Account() {
  const api = useApi();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [authFormValues, setAuthFormValues] = useState({
    ...initialAuthFormValues,
  });
  const [myAccount, setMyAccount] = useState<Record<string, any>>();
  const [submitting, setSubmitting] = useState(false);

  const [initialEmail, setInitialEmail] = useState<string | undefined>();

  const { session } = useAppSelector((state) => state.auth);

  const fetchAccountData = async () => {
    const response = await api.get(`/user/account/${session?.user.id}`);
    setMyAccount(response.data.data);
    setInitialEmail(response.data.data.email);
    const {
      firstName,
      middleName,
      lastName,
      email,
      vat,
      coc,
      iban,
      businessName,
      street,
      housenumber,
      zipcode,
      place,
    } = response.data.data;
    setFormValues({
      firstName,
      middleName: middleName || '',
      lastName,
      email,
      vat,
      coc,
      iban,
      businessName,
      street,
      housenumber,
      zipcode,
      place,
    });
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
  };

  const handleAuthInputChange = (event: any) => {
    const { name, value } = event.target;
    setAuthFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  /**
   * A function that submits the form
   * @param event The event to use
   */
  const handleSubmitEmail = useCallback(
    async (event: any) => {
      event?.preventDefault();
      setSubmitting(true);
      try {
        if (initialEmail === undefined) {
          throw new Error('Initial email was not set!');
        }

        if (session?.user.id) {
          const response = await AuthService.changeEmail(
            api,
            initialEmail,
            formValues.email,
            session?.user.id,
          );
          if (response?.success) {
            dispatch(
              emitFeedback({
                type: FeedbackType.OK,
                message: `Email is succesvol verzonden naar ${formValues.email}`,
              }),
            );
            history.push('/account');
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [session?.user.id, formValues, initialEmail],
  );

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    if (myAccount === undefined) return;
    const body = {
      firstName: formValues.firstName,
      middleName: formValues.middleName,
      lastName: formValues.lastName,
      email: formValues.email,
      vat: formValues.vat,
      coc: formValues.coc,
      iban: formValues.iban,
      businessName: formValues.businessName,
      street: formValues.street,
      housenumber: formValues.housenumber,
      zipcode: formValues.zipcode,
      place: formValues.place,
    };
    setSubmitting(true);
    try {
      const response = await api.put(`/user/account/${myAccount.id}`, body);
      if (response.data.success) {
        dispatch(updateName(formValues.firstName));
        dispatch(
          emitFeedback({
            type: FeedbackType.OK,
            message: 'Account succesvol bijgewerkt!',
          }),
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitAuth = async (event: any) => {
    event?.preventDefault();
    if (myAccount === undefined) return;
    const body = {
      email: myAccount.email,
      currentPassword: authFormValues.currentPassword,
      newPassword: authFormValues.newPassword,
    };
    setSubmitting(true);
    try {
      const response = await api.post(`/auth/change-password`, body);
      if (response.data.success) {
        dispatch(
          emitFeedback({
            type: FeedbackType.OK,
            message: 'Authenticatie succesvol bijgewerkt!',
          }),
        );
        setAuthFormValues({ ...initialAuthFormValues });
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchAccountData();
  }, []);

  return (
    <>
      <PageTitle category="Account" subcategory="Bewerken" />
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper>
              <Box p={2}>
                <LogoTitle title="Emailadres bewerken" />
                <Box my={2}>
                  <Divider />
                </Box>
                <form onSubmit={(e) => handleSubmitEmail(e)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Emailadres"
                        name="email"
                        required
                        type="email"
                        variant="outlined"
                        value={formValues.email}
                        onChange={handleInputChange}
                      />
                      <small>
                        Omdat het bewerken van een emailadres een aparte
                        procedure is, staat dit onderdeel apart
                      </small>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={submitting}
                      >
                        {submitting ? 'Laden...' : 'Wijzigen'}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box p={2}>
                {myAccount ? (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <LogoTitle title="Profielgegevens" />
                        <Box my={2}>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Voornaam"
                          name="firstName"
                          required
                          type="text"
                          variant="outlined"
                          value={formValues.firstName}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Tussenvoegsel"
                          name="middleName"
                          type="text"
                          variant="outlined"
                          value={formValues.middleName}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Achternaam"
                          name="lastName"
                          required
                          type="text"
                          variant="outlined"
                          value={formValues.lastName}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      {myAccount.isBusiness ? (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="BTW nr"
                              name="vat"
                              type="text"
                              variant="outlined"
                              value={formValues.vat}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="KVK nr"
                              name="coc"
                              type="text"
                              variant="outlined"
                              value={formValues.coc}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="IBAN"
                              name="iban"
                              type="text"
                              variant="outlined"
                              value={formValues.iban}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Bedrijfsnaam"
                              name="businessName"
                              type="text"
                              variant="outlined"
                              value={formValues.businessName}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Straat"
                              name="street"
                              type="text"
                              variant="outlined"
                              value={formValues.street}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Huisnummer"
                              name="housenumber"
                              type="text"
                              variant="outlined"
                              value={formValues.housenumber}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Postcode"
                              name="zipcode"
                              type="text"
                              variant="outlined"
                              value={formValues.zipcode}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Plaats"
                              name="place"
                              type="text"
                              variant="outlined"
                              value={formValues.place}
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      <Grid item xs={12} sm={12}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                          disabled={submitting}
                        >
                          {submitting ? 'Wijzigen..' : 'Wijzigen'}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <Loader />
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box p={2}>
                <form onSubmit={handleSubmitAuth}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <LogoTitle title="Wachtwoord" />
                      <Box my={2}>
                        <Divider />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Huidig wachtwoord"
                        name="currentPassword"
                        required
                        type="password"
                        variant="outlined"
                        value={authFormValues.currentPassword}
                        onChange={handleAuthInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Nieuw wachtwoord"
                        name="newPassword"
                        required
                        type="password"
                        variant="outlined"
                        value={authFormValues.newPassword}
                        onChange={handleAuthInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Nieuw wachtwoord (herhalen)"
                        name="newPasswordRepeat"
                        required
                        type="password"
                        variant="outlined"
                        value={authFormValues.newPasswordRepeat}
                        onChange={handleAuthInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={
                          submitting ||
                          authFormValues.newPassword !==
                            authFormValues.newPasswordRepeat
                        }
                      >
                        {submitting ? 'Wijzigen..' : 'Wijzigen'}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
