import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  DeleteForeverRounded as DeleteIcon,
  NoteRounded as AddIcon,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { formatPrice } from 'common';
import { AsyncTable, PageTitle } from 'components';
import { useApi } from 'hooks';

export function ListManual() {
  const api = useApi();
  const history = useHistory();

  const [manuals, setManuals] = useState<Record<string, any>[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjs().startOf('month').format('YYYY-MM-DD'),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    dayjs().endOf('month').format('YYYY-MM-DD'),
  );

  const range = useMemo(
    () => `?startDate=${selectedStartDate}&endDate=${selectedEndDate}`,
    [selectedStartDate, selectedEndDate],
  );

  const fetchData = useCallback(async () => {
    setLoaded(false);
    setError(false);
    try {
      const response = await api.get(`/manual-amount${range}`);
      setManuals(response.data.data);
    } catch {
      setError(true);
    } finally {
      setLoaded(true);
    }
  }, [range]);

  const handleDelete = async (manualId: number) => {
    const selection = confirm(
      'Weet u zeker dat u dit bedrag wilt verwijderen?',
    );
    if (selection === true) {
      await api.delete(`/manual-amount/${manualId}`);
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageTitle category="Handmatig" subcategory="Bedragen" />
      <Container maxWidth="md" disableGutters>
        <Box display="flex" justifyContent="flex-end" pb={2}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => history.push('/handmatig/nieuw')}
          >
            Nieuw bedrag <AddIcon />
          </Button>
        </Box>
        <Paper>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Periode</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="date"
                  fullWidth
                  label="Start periode"
                  variant="outlined"
                  value={selectedStartDate}
                  onChange={(event) => setSelectedStartDate(event.target.value)}
                  onBlur={fetchData}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="date"
                  fullWidth
                  label="Eind periode"
                  variant="outlined"
                  value={selectedEndDate}
                  onChange={(event) => setSelectedEndDate(event.target.value)}
                  onBlur={fetchData}
                />
              </Grid>
              <Grid item xs={12}>
                <AsyncTable
                  columns={[
                    'Datum',
                    'Van',
                    'Naar',
                    'Omschrijving',
                    'Aantal',
                    'Acties',
                  ]}
                  loading={!loaded && !error}
                  error={!!error}
                  empty={!!loaded && manuals.length < 1}
                >
                  {manuals.map((manual) => (
                    <TableRow key={manual.id}>
                      <TableCell>
                        {dayjs(manual.date).format('D MMMM YYYY')}
                      </TableCell>
                      <TableCell>
                        {manual.fromUser.firstName}
                        {manual.fromUser.middleName !== null
                          ? ` ${manual.fromUser.middleName} `
                          : ' '}
                        {manual.fromUser.lastName}
                      </TableCell>
                      <TableCell>
                        {manual.toUser.firstName}
                        {manual.toUser.middleName !== null
                          ? ` ${manual.toUser.middleName} `
                          : ' '}
                        {manual.toUser.lastName}
                      </TableCell>
                      <TableCell>{manual.note}</TableCell>
                      <TableCell>{formatPrice(manual.amount)}</TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(manual.id)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </AsyncTable>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
