import { createSlice } from '@reduxjs/toolkit';

/**
 * The property types which are used by the `App` feature state
 */
interface AppState {
  sidebarOpen: boolean;
}

/**
 * The default property values which are used by the `App` feature state
 */
const initialState: AppState = {
  sidebarOpen: false,
};

/**
 * A slice which handles all the logic for the `App` feature state
 */
const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openSidebar: (state) => {
      state.sidebarOpen = true;
    },
    closeSidebar: (state) => {
      state.sidebarOpen = false;
    },
  },
});

/**
 * The actions available from within the `App` feature state
 */
export const { openSidebar, closeSidebar } = slice.actions;

/**
 * The reducer to interact with the `App` feature state
 */
export const appReducer = slice.reducer;
