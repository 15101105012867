import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { LogoTitle, PageTitle } from 'components';

/**
 * The format of the date to use
 */
const DATE_FORMAT = 'YYYY-MM-DD';

export function ExportExcel(): ReactElement {
  const history = useHistory();

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format(DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format(DATE_FORMAT),
  );

  /**
   * An effect that prevents start date overflow
   */
  useEffect(() => {
    if (dayjs(startDate).unix() > dayjs(endDate).unix()) {
      setEndDate(dayjs(startDate).format(DATE_FORMAT));
    }
  }, [startDate]);

  /**
   * An effect that prevents end date overflow
   */
  useEffect(() => {
    if (dayjs(endDate).unix() < dayjs(startDate).unix()) {
      setStartDate(dayjs(endDate).format(DATE_FORMAT));
    }
  }, [endDate]);

  /**
   * A function that creates a new date from a string
   * @param date The date string to format
   * @returns A new date
   */
  const makeDate = (date: string) => dayjs(date).format(DATE_FORMAT);

  /**
   * A function that handles the form submission
   */
  const handleSubmit = (event: any) => {
    event.preventDefault();
    history.push(
      `/export/excel/bekijken?startDate=${startDate}&endDate=${endDate}`,
    );
  };

  return (
    <>
      <PageTitle
        category="Export"
        subcategory="Excel document"
        subtitle="Exporteer gegevens naar een excel document"
        cancelLink="/export"
      />
      <Container maxWidth="sm">
        <Paper>
          <Box p={2}>
            <LogoTitle title="Selecteer periode" />
            <Box my={2}>
              <Divider />
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Datum van"
                    required
                    type="date"
                    variant="outlined"
                    value={startDate}
                    onChange={(event) =>
                      setStartDate(makeDate(event.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Datum tot"
                    required
                    type="date"
                    variant="outlined"
                    value={endDate}
                    onChange={(event) =>
                      setEndDate(makeDate(event.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Bekijk exports
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
