/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';

import { LogoTitle } from 'components';
import { emitFeedback, FeedbackType, setRemember } from 'features';
import { useLoginForm } from 'forms';
import { useApi, useAppDispatch, useAppSelector } from 'hooks';

/**
 * A view that allows the user to log in
 *
 * @returns The `Login` view
 */
export function Login(): ReactElement {
  const api = useApi();
  const dispatch = useAppDispatch();

  const { formValues, handleInputChange, handleSubmit } = useLoginForm();

  const [forgotPassword, setForgotPassword] = useState(false);
  const [isRequesting, setRequesting] = useState(false);

  const handleForgotPassword = async () => {
    setRequesting(true);
    try {
      const response = await api.post('/auth/forgot-password', {
        email: formValues.email,
      });
      if (response?.data?.success) {
        dispatch(
          emitFeedback({
            type: FeedbackType.OK,
            message: 'Wachtwoord succesvol aangevraagd, bekijk uw mailbox.',
          }),
        );
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    dispatch(setRemember(true));
  }, []);

  return (
    <Box
      width="100%"
      minHeight="inherit"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Paper>
        <Box p={2} maxWidth="500px">
          <LogoTitle title="Inloggen" />
          <Box my={2}>
            <Divider />
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="email"
                  autoFocus
                  fullWidth
                  label="Emailadres"
                  name="email"
                  onChange={handleInputChange}
                  placeholder="Voer e-mailadres in"
                  required
                  type="email"
                  value={formValues.email}
                  variant="outlined"
                />
              </Grid>
              {!forgotPassword && (
                <Grid item xs={12}>
                  <TextField
                    autoComplete="password"
                    fullWidth
                    label="Wachtwoord"
                    name="password"
                    onChange={handleInputChange}
                    placeholder="Voer e-mailadres in"
                    required
                    type="password"
                    value={formValues.password}
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={forgotPassword}
                      onChange={(e) => setForgotPassword(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Wachtwoord vergeten"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type={forgotPassword ? 'button' : 'submit'}
                  onClick={forgotPassword ? handleForgotPassword : undefined}
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isRequesting}
                >
                  {forgotPassword ? 'Wachtwoord aanvragen' : 'Inloggen'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Box>
  );
}
