import type { ReactNode } from 'react';

import { Roles } from 'features';

/**
 * An interface that defines the structure of a `Route`,
 * this will be used for consistent routing throughout the application
 */
export type Route = {
  access: Roles[];
  display: DisplayType;
  exact?: boolean;
  fullScreen?: boolean;
  icon: any;
  name: string;
  path: string;
  view: ReactNode;
};

/**
 * The way a route can be displayed
 */
export enum DisplayType {
  SIDEBAR,
  HIDDEN,
}
