import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { DeleteForeverRounded } from '@material-ui/icons';
import dayjs from 'dayjs';

import { formatPrice } from 'common';
import { AsyncTable, PageTitle, TableModal } from 'components';
import { useApi } from 'hooks';

const DATE_FORMAT = 'YYYY-MM-DD';

export function Bulk(): ReactElement {
  const api = useApi();

  const [bulks, setBulks] = useState<Record<string, any>[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const [thisUserOnly, setThisUserOnly] = useState(false);
  const [selectedBulk, setSelectedBulk] = useState<any>();

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format(DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format(DATE_FORMAT),
  );

  /**
   * An effect that prevents start date overflow
   */
  useEffect(() => {
    if (dayjs(startDate).unix() > dayjs(endDate).unix()) {
      setEndDate(dayjs(startDate).format(DATE_FORMAT));
    }
  }, [startDate]);

  /**
   * An effect that prevents end date overflow
   */
  useEffect(() => {
    if (dayjs(endDate).unix() < dayjs(startDate).unix()) {
      setStartDate(dayjs(endDate).format(DATE_FORMAT));
    }
  }, [endDate]);

  const handleLoadBulk = async () => {
    try {
      setBulks([]);
      setIsLoaded(false);
      setIsError(false);

      const query = `?startDate=${startDate}&endDate=${endDate}`;

      const response = await api.get(
        thisUserOnly ? `/bulk/user${query}` : `/bulk${query}`,
      );
      setBulks(response.data.data);
      setIsLoaded(true);
    } catch {
      setIsError(true);
    }
  };

  const makeDate = (date: string) => dayjs(date).format(DATE_FORMAT);

  const handleDelete = async (bulkId: number) => {
    const selection = confirm('Weet u zeker dat u deze bulk wilt verwijderen?');
    if (selection === true) {
      await api.delete(`/bulk/${bulkId}`);
      handleLoadBulk();
    }
  };

  useEffect(() => {
    handleLoadBulk();
  }, []);

  return (
    <>
      <TableModal
        title="Gebruikers"
        open={!!selectedBulk}
        onClose={() => setSelectedBulk(undefined)}
        columns={['Gebruiker', 'Items']}
        rows={(selectedBulk?.bulkUsers || []).map(
          ({ user, amountItems, totalPrice }: any) => ({
            category: `${user.firstName}${
              user.middleName ? ` ${user.middleName} ` : ' '
            }${user.lastName}`,
            value: `${amountItems} items à ${formatPrice(totalPrice)}`,
          }),
        )}
      />
      <PageTitle category="Bulk" subcategory="Overzicht" />
      <Box
        display="flex"
        justifyContent="start"
        alignContent="center"
        gridGap={12}
        flexWrap="wrap"
      >
        <TextField
          label="Datum van"
          required
          type="date"
          variant="outlined"
          value={startDate}
          onChange={(event) => setStartDate(makeDate(event.target.value))}
          size="small"
        />
        <TextField
          label="Datum tot"
          required
          type="date"
          variant="outlined"
          value={endDate}
          onChange={(event) => setEndDate(makeDate(event.target.value))}
          size="small"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={thisUserOnly}
              onChange={(e) => setThisUserOnly(e.target.checked)}
              color="primary"
            />
          }
          label="Alléén huidige gebruiker"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={handleLoadBulk}
        >
          Toepassen
        </Button>
      </Box>
      <Box p={2}>
        <Box minHeight={400}>
          <AsyncTable
            columns={[
              '#',
              'Datum',
              'Omschrijving',
              'Prijs',
              'Gebruikers',
              'Klant',
              'Actie',
            ]}
            loading={!isLoaded && !isError}
            error={!!isError}
            empty={!!isLoaded && bulks.length < 1}
            striped={false}
          >
            {bulks.map((bulk) => (
              <TableRow key={bulk.id}>
                <TableCell>{bulk.id}</TableCell>
                <TableCell>{dayjs(bulk.date).format('D MMMM YYYY')}</TableCell>
                <TableCell>{bulk.note}</TableCell>
                <TableCell>{formatPrice(bulk.bulkPrice)}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setSelectedBulk(bulk)}
                  >
                    Bekijk gebruikers
                  </Button>
                </TableCell>
                <TableCell>{bulk.customers.description}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDelete(bulk.id)}>
                    <DeleteForeverRounded color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </AsyncTable>
        </Box>
      </Box>
    </>
  );
}
