import { ReactElement } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
} from '@material-ui/core';

import { AsyncTable } from 'components';

/**
 * The property types which are used by the `TableModal` component
 */
export interface TableModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  columns: [string, string];
  rows: { category: string; value: string }[];
}

/**
 * A component that shows a modal with table data
 *
 * @param title The title of the modal
 * @param open If the modal should be open
 * @param onClose The function to call on close
 * @param columns Two columns to display
 * @param rows The rows to display
 *
 * @returns The `TableModal` component
 */
export function TableModal({
  title,
  open,
  onClose,
  columns,
  rows,
}: TableModalProps): ReactElement {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <AsyncTable columns={columns} outlined>
          {rows.map(({ category, value }) => (
            <TableRow key={category}>
              <TableCell>{category}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </AsyncTable>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Sluiten</Button>
      </DialogActions>
    </Dialog>
  );
}
