/**
 * The types of feedback which can be displayed
 */
export enum FeedbackType {
  OK = 'success',
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error',
}

/**
 * A type that defines how feedback should be sent within the system
 */
export type Feedback = {
  type: FeedbackType;
  message: string;
};
