import { lighten, makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `StatisticCard` component
 */
export default makeStyles((theme) => ({
  description: {
    opacity: 0.7,
  },
  root: {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  text: {
    fontWeight: 600,
  },
}));
