import { darken, makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `Appbar` component
 */
export default makeStyles((theme) => ({
  avatar: {
    cursor: 'pointer',
    backgroundColor: darken(theme.palette.secondary.main, 0.075),
    padding: theme.spacing(0.75),
  },
  link: {
    textDecoration: 'none',
  },
  logo: {
    height: (theme.mixins.toolbar.minHeight as number) / 1.5,
  },
  menuIconClosed: {
    transform: 'rotate(-180deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuIconOpen: {
    transform: 'rotate(0)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  name: {
    fontWeight: 'normal',
    marginRight: theme.spacing(1),
  },
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    padding: theme.spacing(0, 1),
    paddingRight: theme.spacing(2),
  },
}));
