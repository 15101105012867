import axios, { AxiosInstance } from 'axios';

import { requestInterceptor, responseInterceptor } from 'common';

/**
 * An interface that defines how the API will respond
 */
export interface ApiResponse<T = Record<string, unknown>> {
  readonly success: boolean;
  readonly message: string;
  readonly errorMessage: string;
  readonly data: T;
  readonly error: any;
}

/**
 * An instance of axios to communicate with the API
 */
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Attach an interceptor to reach request
 */
instance.interceptors.request.use(requestInterceptor);

/**
 * Attach an interceptor to reach response
 */
instance.interceptors.response.use(
  (response) => responseInterceptor(response, undefined),
  (error) => responseInterceptor(undefined, error),
);

/**
 * A hook that provides access to the back-end API
 *
 * @returns The `useApi` hook
 */
export function useApi(): AxiosInstance {
  return instance;
}
