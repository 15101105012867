import { ReactElement, useState } from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import { VisibilityOffRounded as HideSourceIcon } from '@material-ui/icons';

import useStyles from './StatisticCard.style';

/**
 * The property types which are used by the `StatisticCard` component
 */
export interface StatisticCardProps {
  text: string;
  description: string;
  enableText: boolean;
  hideTextBtn: boolean;
}

/**
 * A component that displays some statistic
 *
 * @param text The text (number, string, etc) to display
 * @param description The description of the text
 *
 * @returns The `StatisticCard` component
 */
export function StatisticCard({
  text,
  description,
  enableText,
  hideTextBtn,
}: StatisticCardProps): ReactElement {
  const styles = useStyles();

  const [showText, setShowText] = useState(enableText);

  const handleClick = () => {
    setShowText(!showText);
  };

  const pointer = { cursor: 'pointer' };

  return (
    <Paper elevation={2} className={styles.root}>
      <Box p={2}>
        <Typography variant="h3" className={styles.text}>
          {showText ? text : '€00,00'}
        </Typography>

        <Typography variant="h6" className={styles.description}>
          {description}{' '}
          {!hideTextBtn && (
            <HideSourceIcon style={pointer} onClick={handleClick} />
          )}
        </Typography>
      </Box>
    </Paper>
  );
}
