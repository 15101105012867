import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `LogoTitle` component
 */
export default makeStyles((theme) => ({
  image: {
    height: `calc(${theme.typography.h4.fontSize} * 1.25)`,
    opacity: 0.5,
  },
}));
