import { ReactElement } from 'react';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Switch, Route, useLocation } from 'react-router-dom';

import { Layout, FeedbackDisplay, Auth } from 'containers';
import { hasAccess } from 'features';
import { useAppSelector } from 'hooks';
import { getRouteDetails, getRoutes } from 'router';
import { theme } from 'theme';
import { NotFound } from 'views';

/**
 * A container that handles the global app logic
 *
 * @returns The `App` container
 */
export function App(): ReactElement {
  const { pathname } = useLocation();

  const { session } = useAppSelector((state) => state.auth);

  /**
   * A function that checks if the current route is full screen
   * @returns If the current route is fullscreen
   */
  const isFullScreen = () => Boolean(getRouteDetails(pathname)?.fullScreen);

  //! TODO: Zorgen dat ik niet steeds naar de inlogpagina gestuurd wordt.
  // if (!session && pathname !== '/inloggen') {
  //   history.replace('/inloggen');
  // }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FeedbackDisplay />
      <Auth>
        <Layout fullScreen={isFullScreen()}>
          <Switch>
            {getRoutes().map((route) => {
              const access = hasAccess(route.access, session?.token);
              if (access)
                return (
                  <Route key={route.path} path={route.path} exact={route.exact}>
                    {route.view}
                  </Route>
                );
              return null;
            })}
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </Auth>
    </ThemeProvider>
  );
}
