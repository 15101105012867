import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { populateNull } from 'common';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';

const initialFormValues = {
  description: '',
  website: '',
  titleTag: '',
  dateTag: '',
  automaticBulk: false,
  automaticBundle: false,
  price: {
    entranceDate: dayjs().format('YYYY-MM-DD'),
    exitDate: dayjs().format('YYYY-MM-DD'),
    photo: '',
    video: '',
    broadcast: '',
    paper: '',
    paperSmall: '',
    maximum: '',
    bulk: '',
    bundle: '',
    bundleFactorPhoto: '',
    bundleFactorVideo: '',
  },
};

export function useEditCustomerForm(customerId: number): {
  formValues: typeof initialFormValues;
  handleInputChange: (event: any) => void;
  handleSubmit: (event: any) => void;
  submitting: boolean;
  fetching: boolean;
} {
  const api = useApi();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [formValues, setFormValues] = useState(_.cloneDeep(initialFormValues));
  const [fetching, setFetching] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  /**
   * A function thet fetches and sets the user data
   * @param userId The id of the user to fetch
   */
  const fetchData = async () => {
    setFetching(true);
    const response = await api.get(`/customer/${customerId}`);
    setFetching(false);
    setFormValues(populateNull(response.data.data));
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormValues((prevFormValues) => {
        const prev = _.cloneDeep(prevFormValues);

        const sub = (prev as any)[parent];

        return {
          ...prev,
          [parent]: {
            ...sub,
            [child]: value,
          },
        };
      });
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    setSubmitting(true);
    try {
      const response = await api.put(
        `/customer/${customerId}`,
        JSON.stringify({
          ...formValues,
          price: {
            ...formValues.price,
            entranceDate:
              formValues.price.entranceDate !== ''
                ? formValues.price.entranceDate
                : null,
            exitDate:
              formValues.price.exitDate !== ''
                ? formValues.price.exitDate
                : null,
          },
        }),
      );

      if (response.data.success === true) {
        dispatch(
          emitFeedback({
            type: FeedbackType.OK,
            message: `${formValues.description} is succesvol bewerkt!`,
          }),
        );
        history.push('/afnemers');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * An effect which handles the loading of user data
   */
  useEffect(() => {
    fetchData();
  }, [customerId]);

  return {
    formValues,
    handleInputChange,
    handleSubmit,
    submitting,
    fetching,
  };
}
