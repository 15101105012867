import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Feedback } from 'features';

/**
 * The property types which are used by the `Feedback` feature state
 */
interface FeedbackState {
  currentFeedback?: Feedback;
}

/**
 * The default property values which are used by the `Feedback` feature state
 */
const initialState: FeedbackState = {};

/**
 * A slice which handles all the logic for the `Feedback` feature state
 */
const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    emitFeedback: (state, action: PayloadAction<Feedback>) => {
      state.currentFeedback = action.payload;
    },
    clearFeedback: (state) => {
      state.currentFeedback = undefined;
    },
  },
});

/**
 * The actions available from within the `Feedback` feature state
 */
export const { emitFeedback, clearFeedback } = slice.actions;

/**
 * The reducer to interact with the `Feedback` feature state
 */
export const feedbackReducer = slice.reducer;
