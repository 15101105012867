import { makeStyles } from '@material-ui/core';

import { DRAWER_WIDTH_EXPANDED } from 'common';

/**
 * The height of the footer in pixels
 */
const FOOTER_HEIGHT = 100;

/**
 * The styles which are used by the `Layout` container
 */
export default makeStyles((theme) => ({
  content: {
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      marginTop: 48,
      minHeight: `calc(100vh - 48px - ${FOOTER_HEIGHT}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
      minHeight: `calc(100vh - 64px - ${FOOTER_HEIGHT}px)`,
    },
    flexGrow: 1,
    marginTop: 56,
    minHeight: `calc(100vh - 56px - ${FOOTER_HEIGHT}px)`,
  },
  contentSidebarClosed: {
    marginLeft: theme.spacing(7),
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentSidebarOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: DRAWER_WIDTH_EXPANDED,
      transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: `calc(100vw - ${DRAWER_WIDTH_EXPANDED}px)`,
      transform: `translateX(${DRAWER_WIDTH_EXPANDED}px)`,
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  copyright: {
    height: FOOTER_HEIGHT,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& p': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(1, 0),
    },
    '& p span': {
      fontWeight: 'bold',
    },
    '& p:not(:first-of-type)': {
      fontStyle: 'italic',
      opacity: 0.6,
    },
    '& svg': {
      marginLeft: theme.spacing(0.5),
      width: 15,
    },
    opacity: 0.6,
  },
  logo: {
    height: (theme.mixins.toolbar.minHeight as number) / 1.5,
  },
  children: {
    minHeight: 'inherit',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
    padding: theme.spacing(4),
  },
}));
