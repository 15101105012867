import { ReactElement, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { Loader, LogoTitle, PageTitle } from 'components';
import { useEditUserForm } from 'forms';

/**
 * The property types which are used by the `EditUser` view routing parameters
 */
export interface EditUserParams {
  id: string;
}

/**
 * A view that allows the user to edit an existing user
 *
 * @returns The `EditUser` view
 */
export function EditUser(): ReactElement {
  const { id }: EditUserParams = useParams();

  const { formValues, handleInputChange, handleSubmit, submitting, fetching } =
    useEditUserForm(parseInt(id, 10) || -1);

  const [businessBtn, setBusinessBtn] = useState(false);
  const [editorBtn, setEditorBtn] = useState(false);

  return (
    <>
      <PageTitle
        category="Gebruikers"
        subcategory="Bewerken"
        cancelLink="/gebruikers"
      />
      <Container maxWidth="sm" disableGutters>
        <Paper>
          <Box p={2}>
            <LogoTitle title="Emailadres bewerken" />
            <Box my={2}>
              <Divider />
            </Box>
            <form onSubmit={(e) => handleSubmit(e, 'email')}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Emailadres"
                    name="email"
                    required
                    type="email"
                    variant="outlined"
                    value={formValues.email}
                    onChange={handleInputChange}
                  />
                  <small>
                    Omdat het bewerken van een emailadres een aparte procedure
                    is, staat dit onderdeel apart
                  </small>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={submitting}
                  >
                    {submitting ? 'Laden...' : 'Wijzigen'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
        <Box my={4} />
        <Paper>
          <Box p={2}>
            <LogoTitle title="Profielgegevens bewerken" />
            <Box my={2}>
              <Divider />
            </Box>
            {!fetching ? (
              <form onSubmit={(e) => handleSubmit(e, 'profile')}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Voornaam"
                      name="firstName"
                      required
                      type="text"
                      variant="outlined"
                      value={formValues.firstName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Tussenvoegsel"
                      name="middleName"
                      type="text"
                      variant="outlined"
                      value={formValues.middleName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Achternaam"
                      name="lastName"
                      required
                      type="text"
                      variant="outlined"
                      value={formValues.lastName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Foto %"
                      name="photoPercentage"
                      required
                      type="number"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                          max: 100,
                        },
                      }}
                      value={formValues.photoPercentage}
                      onChange={(e) => handleInputChange(e, parseInt)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Video %"
                      name="videoPercentage"
                      required
                      type="number"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                          max: 100,
                        },
                      }}
                      value={formValues.videoPercentage}
                      onChange={(e) => handleInputChange(e, parseInt)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="BTW %"
                      name="vatPercentage"
                      required
                      type="number"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                          max: 100,
                        },
                      }}
                      value={formValues.vatPercentage}
                      onChange={(e) => handleInputChange(e, parseInt)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Geholpen %"
                      name="sendingHelpPercentage"
                      required
                      type="number"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                          max: 100,
                        },
                      }}
                      value={formValues.sendingHelpPercentage}
                      onChange={(e) => handleInputChange(e, parseInt)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Bulk %"
                      name="bulkPercentage"
                      required
                      type="number"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                          max: 100,
                        },
                      }}
                      value={formValues.bulkPercentage}
                      onChange={(e) => handleInputChange(e, parseInt)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.isBusiness ?? businessBtn}
                          onChange={(e) => {
                            setBusinessBtn(e.target.checked);
                            handleInputChange({
                              target: {
                                value: e.target.checked,
                                name: e.target.name,
                              },
                            });
                          }}
                          color="primary"
                          name="isBusiness"
                        />
                      }
                      label="Heeft een bedrijf"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.isEditor ?? editorBtn}
                          onChange={(e) => {
                            setEditorBtn(e.target.checked);
                            handleInputChange({
                              target: {
                                value: e.target.checked,
                                name: e.target.name,
                              },
                            });
                          }}
                          color="primary"
                          name="isEditor"
                        />
                      }
                      label="Is redacteur"
                    />
                  </Grid>
                  {formValues.isBusiness ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="BTW Nummer"
                          name="vat"
                          required
                          type="text"
                          variant="outlined"
                          value={formValues.vat}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="KVK Nummer"
                          name="coc"
                          type="text"
                          variant="outlined"
                          value={formValues.coc}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="IBAN"
                          name="iban"
                          type="text"
                          variant="outlined"
                          value={formValues.iban}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Bedrijfsnaam"
                          name="businessName"
                          type="text"
                          variant="outlined"
                          value={formValues.businessName}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Straat"
                          name="street"
                          type="text"
                          variant="outlined"
                          value={formValues.street}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Huisnummer"
                          name="housenumber"
                          type="text"
                          variant="outlined"
                          value={formValues.housenumber}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Postcode"
                          name="zipcode"
                          type="text"
                          variant="outlined"
                          value={formValues.zipcode}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Plaats"
                          name="place"
                          type="text"
                          variant="outlined"
                          value={formValues.place}
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={submitting}
                    >
                      {submitting ? 'Laden...' : 'Wijzigen'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Loader />
            )}
          </Box>
        </Paper>
      </Container>
    </>
  );
}
