import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import { LogoTitle, PageTitle } from 'components';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';

/**
 * The format of the date to use
 */
const DATE_FORMAT = 'YYYY-MM-DD';

export function ExportPDF(): ReactElement {
  const api = useApi();
  const dispatch = useAppDispatch();

  const [customers, setCustomers] = useState<Record<string, any>[]>([]);

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format(DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format(DATE_FORMAT),
  );
  const [customerId, setCustomerId] = useState('');

  /**
   * An effect that prevents start date overflow
   */
  useEffect(() => {
    if (dayjs(startDate).unix() > dayjs(endDate).unix()) {
      setEndDate(dayjs(startDate).format(DATE_FORMAT));
    }
  }, [startDate]);

  /**
   * An effect that prevents end date overflow
   */
  useEffect(() => {
    if (dayjs(endDate).unix() < dayjs(startDate).unix()) {
      setStartDate(dayjs(endDate).format(DATE_FORMAT));
    }
  }, [endDate]);

  useEffect(() => {
    const fetchCustomers = async () => {
      const response = await api.get('/customer');
      setCustomers(response.data.data);
    };
    fetchCustomers();
  }, []);

  /**
   * A function that creates a new date from a string
   * @param date The date string to format
   * @returns A new date
   */
  const makeDate = (date: string) => dayjs(date).format(DATE_FORMAT);

  /**
   * A function that handles the form submission
   */
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!customerId) return;
    const response = await api.get(
      `pdf?startDate=${startDate}&endDate=${endDate}&customerId=${customerId}`,
      { responseType: 'blob' },
    );
    try {
      const { data } = response;
      const selectedCustomer = customers.find(
        (customer) => customer.id === customerId,
      );
      if (!selectedCustomer) return;
      const fileName = `${selectedCustomer.description.replace(
        /[^a-zA-Z0-9]/gi,
        '-',
      )}_${dayjs(startDate).format('D-M-YYYY')}-tm-${dayjs(endDate).format(
        'D-M-YYYY',
      )}.pdf`;
      saveAs(data, fileName);
    } catch {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message:
            'Er is een fout opgetreden tijdens het downloaden van het document.',
        }),
      );
    }
  };

  return (
    <>
      <PageTitle
        category="Export"
        subcategory="PDF document"
        subtitle="Exporteer gegevens naar een pdf document"
        cancelLink="/export"
      />
      <Container maxWidth="sm">
        <Paper>
          <Box p={2}>
            <LogoTitle title="Maak een selectie" />
            <Box my={2}>
              <Divider />
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Datum van"
                    required
                    type="date"
                    variant="outlined"
                    value={startDate}
                    onChange={(event) =>
                      setStartDate(makeDate(event.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Datum tot"
                    required
                    type="date"
                    variant="outlined"
                    value={endDate}
                    onChange={(event) =>
                      setEndDate(makeDate(event.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="from-user-select">Kies afnemer</InputLabel>
                    <Select
                      labelId="from-user-select"
                      label="Kies afnemer *"
                      value={customerId}
                      name="fromUserId"
                      required
                      onChange={(e: any) => setCustomerId(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Selecteer</em>
                      </MenuItem>
                      {customers.map((customer) => (
                        <MenuItem value={customer.id} key={customer.id}>
                          {customer.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!customerId}
                  >
                    Download
                  </Button>
                </Grid>
                {}
              </Grid>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
