import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { DeleteForeverRounded } from '@material-ui/icons';
import dayjs from 'dayjs';

import { formatPrice } from 'common';
import { AsyncTable, PageTitle, TableModal } from 'components';
import { useApi } from 'hooks';

const DATE_FORMAT = 'YYYY-MM-DD';

export function Bundle(): ReactElement {
  const api = useApi();

  const [bundles, setBundles] = useState<Record<string, any>[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const [thisUserOnly, setThisUserOnly] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState<any>();

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format(DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format(DATE_FORMAT),
  );

  /**
   * An effect that prevents start date overflow
   */
  useEffect(() => {
    if (dayjs(startDate).unix() > dayjs(endDate).unix()) {
      setEndDate(dayjs(startDate).format(DATE_FORMAT));
    }
  }, [startDate]);

  /**
   * An effect that prevents end date overflow
   */
  useEffect(() => {
    if (dayjs(endDate).unix() < dayjs(startDate).unix()) {
      setStartDate(dayjs(endDate).format(DATE_FORMAT));
    }
  }, [endDate]);

  const handleLoadBundle = async () => {
    try {
      setBundles([]);
      setIsLoaded(false);
      setIsError(false);

      const query = `?startDate=${startDate}&endDate=${endDate}`;

      const response = await api.get(
        thisUserOnly ? `/bundle/user${query}` : `/bundle${query}`,
      );
      setBundles(response.data.data);
      setIsLoaded(true);
    } catch {
      setIsError(true);
    }
  };

  const makeDate = (date: string) => dayjs(date).format(DATE_FORMAT);

  const handleDelete = async (bundleId: number) => {
    const selection = confirm(
      'Weet u zeker dat u deze bundle wilt verwijderen?',
    );
    if (selection === true) {
      await api.delete(`/bundle/${bundleId}`);
      handleLoadBundle();
    }
  };

  useEffect(() => {
    handleLoadBundle();
  }, []);

  return (
    <>
      <TableModal
        title="Gebruikers"
        open={!!selectedBundle}
        onClose={() => setSelectedBundle(undefined)}
        columns={['Gebruiker', 'Totaal']}
        rows={(selectedBundle?.bundleUsers || []).map(
          ({
            user,
            amountPhotos,
            amountVideos,
            totalPricePhotos,
            totalPriceVideos,
            userPhotoPercentage,
            userVideoPercentage,
          }: any) => ({
            category: `${user.firstName}${
              user.middleName ? ` ${user.middleName} ` : ' '
            }${user.lastName}`,
            value: `${amountPhotos} foto's en ${amountVideos} video's voor ${formatPrice(
              Number(totalPricePhotos) * (userPhotoPercentage / 100) +
                Number(totalPriceVideos) * (userVideoPercentage / 100),
            )}`,
          }),
        )}
      />
      <PageTitle category="Bundle" subcategory="Overzicht" />
      <Box
        display="flex"
        justifyContent="start"
        alignContent="center"
        gridGap={12}
        flexWrap="wrap"
      >
        <TextField
          label="Datum van"
          required
          type="date"
          variant="outlined"
          value={startDate}
          onChange={(event) => setStartDate(makeDate(event.target.value))}
          size="small"
        />
        <TextField
          label="Datum tot"
          required
          type="date"
          variant="outlined"
          value={endDate}
          onChange={(event) => setEndDate(makeDate(event.target.value))}
          size="small"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={thisUserOnly}
              onChange={(e) => setThisUserOnly(e.target.checked)}
              color="primary"
            />
          }
          label="Alléén huidige gebruiker"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={handleLoadBundle}
        >
          Toepassen
        </Button>
      </Box>
      <Box p={2}>
        <Box minHeight={400}>
          <AsyncTable
            columns={[
              '#',
              'Datum',
              'Omschrijving',
              'Prijs',
              'Gebruikers',
              'Klant',
              'Actie',
            ]}
            loading={!isLoaded && !isError}
            error={!!isError}
            empty={!!isLoaded && bundles.length < 1}
            striped={false}
          >
            {bundles.map((bundle) => (
              <TableRow key={bundle.id}>
                <TableCell>{bundle.id}</TableCell>
                <TableCell>
                  {dayjs(bundle.date).format('D MMMM YYYY')}
                </TableCell>
                <TableCell>{bundle.note}</TableCell>
                <TableCell>{formatPrice(bundle.bundlePrice)}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setSelectedBundle(bundle)}
                  >
                    Bekijk gebruikers
                  </Button>
                </TableCell>
                <TableCell>{bundle.customers.description}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDelete(bundle.id)}>
                    <DeleteForeverRounded color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </AsyncTable>
        </Box>
      </Box>
    </>
  );
}
