import {
  Box,
  Container,
  Divider,
  Paper,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { Loader, LogoTitle, PageTitle } from 'components';
import { useEditCustomerForm } from 'forms';

export interface EditCustomerParams {
  id: string;
}

export function EditCustomer() {
  const { id }: EditCustomerParams = useParams();

  const { formValues, handleInputChange, handleSubmit, submitting, fetching } =
    useEditCustomerForm(parseInt(id, 10) || -1);

  return (
    <>
      <PageTitle
        category="Afnemers"
        subcategory="Bewerken"
        cancelLink="/afnemers"
      />
      <Container maxWidth="sm" disableGutters>
        <Paper>
          <Box p={2}>
            <LogoTitle title="Afnemer bewerken" />
            <Box my={2}>
              <Divider />
            </Box>
            {!fetching ? (
              <form onSubmit={(e) => handleSubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Algemeen</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Omschrijving"
                      name="description"
                      required
                      type="text"
                      variant="outlined"
                      value={formValues.description}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Website"
                      name="website"
                      type="text"
                      variant="outlined"
                      value={formValues.website}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Title Tag (xPath)"
                      name="titleTag"
                      type="text"
                      variant="outlined"
                      value={formValues.titleTag}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Date Tag (xPath)"
                      name="dateTag"
                      type="text"
                      variant="outlined"
                      value={formValues.dateTag}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Tarieven</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.automaticBulk}
                          onChange={(e) =>
                            handleInputChange({
                              target: {
                                name: e.target.name,
                                value: e.target.checked,
                              },
                            })
                          }
                          name="automaticBulk"
                          color="primary"
                        />
                      }
                      label="Ondersteunt bulk"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.automaticBundle}
                          onChange={(e) =>
                            handleInputChange({
                              target: {
                                name: e.target.name,
                                value: e.target.checked,
                              },
                            })
                          }
                          name="automaticBundle"
                          color="primary"
                        />
                      }
                      label="Ondersteunt bundel"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Ingangsdatum"
                      name="price.entranceDate"
                      type="date"
                      variant="outlined"
                      value={formValues.price.entranceDate}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Verloopdatum"
                      name="price.exitDate"
                      type="date"
                      variant="outlined"
                      value={formValues.price.exitDate}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <small>
                      Let op! Als er eenmaal een nieuwe prijs is ingevuld, moet
                      men wachten totdat de ingevulde prijs actief wordt voordat
                      deze gewijzigd kan worden!
                    </small>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Bundel Foto Factor"
                      name="price.bundleFactorPhoto"
                      type="number"
                      variant="outlined"
                      value={formValues.price.bundleFactorPhoto}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Bundel Video Factor"
                      name="price.bundleFactorVideo"
                      type="number"
                      variant="outlined"
                      value={formValues.price.bundleFactorVideo}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Foto (in €)"
                      name="price.photo"
                      type="number"
                      variant="outlined"
                      value={formValues.price.photo}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Video (in €)"
                      name="price.video"
                      type="number"
                      variant="outlined"
                      value={formValues.price.video}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Uitzending (in €)"
                      name="price.broadcast"
                      type="number"
                      variant="outlined"
                      value={formValues.price.broadcast}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Krant (in €)"
                      name="price.paper"
                      type="number"
                      variant="outlined"
                      value={formValues.price.paper}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Kleine krant (in €)"
                      name="price.paperSmall"
                      type="number"
                      variant="outlined"
                      value={formValues.price.paperSmall}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Maximum (in €)"
                      name="price.maximum"
                      type="number"
                      variant="outlined"
                      value={formValues.price.maximum}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Bulk (in €)"
                      name="price.bulk"
                      type="number"
                      variant="outlined"
                      value={formValues.price.bulk}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Bundel (in €)"
                      name="price.bundle"
                      type="number"
                      required
                      variant="outlined"
                      value={formValues.price.bundle}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={submitting}
                    >
                      {submitting ? 'Laden...' : 'Wijzigen'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Loader />
            )}
          </Box>
        </Paper>
      </Container>
    </>
  );
}
