import { AxiosInstance } from 'axios';
import _ from 'lodash';

import { CreateSaleDTO, EditSaleDTO, SaleScrapeDTO } from 'dto';
import { ApiResponse } from 'hooks/useApi';

export class SaleService {
  public static async createSale(
    api: AxiosInstance,
    body: CreateSaleDTO,
  ): Promise<ApiResponse> {
    const photographers: CreateSaleDTO['photographers'] = [];
    body.photographers.forEach((photographer) => {
      photographers.push(_.omit(photographer, 'id'));
    });
    const response = await api.post(
      '/item',
      JSON.stringify({ ...body, photographers }),
    );
    return response.data;
  }

  public static async editSale(
    api: AxiosInstance,
    saleId: number,
    body: EditSaleDTO,
  ): Promise<ApiResponse> {
    const photographers: EditSaleDTO['photographers'] = [];
    body.photographers.forEach((photographer) => {
      photographers.push(_.omit(photographer, 'id'));
    });
    const response = await api.put(
      `/item/${saleId}`,
      JSON.stringify({ ...body, photographers }),
    );
    return response.data;
  }

  public static async deleteUserFromSale(
    api: AxiosInstance,
    saleId: number,
    userId: number,
  ): Promise<ApiResponse> {
    const response = await api.delete(`/item/${saleId}?userId=${userId}`);
    return response?.data;
  }

  public static async scrapeLink(
    api: AxiosInstance,
    link: string,
  ): Promise<ApiResponse<SaleScrapeDTO>> {
    const encoded = btoa(link);
    const response = await api.get(`/item/scrapeItemDetails/${encoded}`);
    return response.data;
  }
}
