import { ReactElement } from 'react';

import { Box, CircularProgress, TableCell, TableRow } from '@material-ui/core';

/**
 * The property types which are used by the `Loader` component
 */
export interface LoaderProps {
  colSpan?: number;
}

/**
 * A component that is used to display something that is loading
 *
 * @param colSpan The span in columns to support tables
 *
 * @returns The `Loader` component
 */
export function Loader({ colSpan = 0 }: LoaderProps): ReactElement {
  const loaderElement = (
    <Box
      width="inherit"
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={3}
    >
      <CircularProgress color="primary" />
    </Box>
  );

  if (colSpan > 0)
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>{loaderElement}</TableCell>
      </TableRow>
    );

  return loaderElement;
}
