import { ReactElement, ReactNode } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  ErrorRounded as ErrorIcon,
  InfoRounded as InfoIcon,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import clsx from 'classnames';

import useStyles from './AsyncTable.style';

/**
 * The property types which are used by the `AsyncTable` component
 */
export interface AsyncTableProps {
  children: ReactNode;
  columns: string[];
  empty?: boolean;
  error?: boolean;
  loading?: boolean;
  striped?: boolean;
  outlined?: boolean;
}

/**
 * A component that handles asynchrounous data loading
 *
 * @param children The items to display when everything goes well
 * @param columns The colums to display
 * @param empty If the table turned out empty
 * @param error If an error occurred
 * @param loading If the table should be loading
 * @param striped If the rows should be striped
 * @param boolean If the table should be outlined
 *
 * @returns The `name of the component (function name for example)` component
 */
export function AsyncTable({
  children,
  columns,
  empty = false,
  error = false,
  loading = false,
  striped = true,
  outlined = false,
}: AsyncTableProps): ReactElement {
  const styles = useStyles();

  /**
   * The state to display if the table is empty
   */
  const tableEmpty = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={clsx(styles.root, styles.empty)}
        >
          <InfoIcon />
          <Typography variant="h6">
            Er zijn geen gegevens beschikbaar...
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );

  /**
   * The state to display if an error occurred in the table
   */
  const tableError = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={clsx(styles.root, styles.error)}
        >
          <ErrorIcon />
          <Typography variant="h6">Er is een fout opgetreden...</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );

  /**
   * The state to display if a table is loading
   */
  const tableLoading = [1, 2, 3, 4, 5].map((num) => (
    <TableRow key={num}>
      {columns.map((col) => (
        <TableCell key={col}>
          <Skeleton variant="text" animation="pulse" />
        </TableCell>
      ))}
    </TableRow>
  ));

  return (
    <TableContainer
      component={Paper}
      variant={outlined ? 'outlined' : 'elevation'}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow className={styles.header}>
            {columns.map((col) => (
              <TableCell key={col}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={clsx({ [styles.body]: striped })}>
          {!!loading && tableLoading}
          {!!error && tableError}
          {!!empty && tableEmpty}
          {!loading && !error && !empty && children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
