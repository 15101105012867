import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Hidden,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  ArrowForwardRounded as TransferIcon,
  ArrowDownwardRounded as TransferIconMobile,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { LogoTitle, PageTitle } from 'components';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';

const initialFormValues = {
  date: dayjs().format('YYYY-MM-DD'),
  note: '',
  amount: '',
  fromUserId: '',
  toUserId: '',
};

export function CreateManual(): ReactElement {
  const api = useApi();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [users, setUsers] = useState<Record<string, any>[]>([]);
  const [creating, setCreating] = useState(false);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    if (name === 'amount') {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: isNaN(value) ? value : parseInt(value, 10),
      }));
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: isNaN(value) ? value : value,
      }));
    }
  };

  const create = async () => {
    setCreating(true);
    try {
      const response = await api.post('/manual-amount', {
        amount: formValues.amount.toString(),
        date: formValues.date,
        note: formValues.note.toString(),
        fromUserId: formValues.fromUserId,
        toUserId: formValues.toUserId,
      });
      if (response.data.success) {
        dispatch(
          emitFeedback({
            type: FeedbackType.OK,
            message: 'Bedrag is succesvol aangemaakt',
          }),
        );
      }
    } catch (error) {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message: 'Er is iets fout gegaan. Probeer later opnieuw!',
        }),
      );
      console.error(error);
    } finally {
      setCreating(false);
      history.push('/handmatig');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get('/user/for-item');
      const userList = response.data.data;
      setUsers(
        userList.sort((a: Record<string, any>, b: Record<string, any>) =>
          a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1,
        ),
      );
    };
    fetchData();
  }, []);

  return (
    <>
      <PageTitle
        category="Handmatig"
        subcategory="Nieuw Bedrag"
        subtitle="Maak een handmatig bedrag over naar een gebruiker"
        cancelLink="/handmatig"
      />
      <Container maxWidth="sm">
        <Paper>
          <Box p={2}>
            <LogoTitle title="Vul de gegevens in" />
            <Box my={2}>
              <Divider />
            </Box>
            <form onSubmit={create}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bedrag (in euro's)"
                    required
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">&euro;</InputAdornment>
                      ),
                    }}
                    name="amount"
                    value={formValues.amount}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Datum"
                    required
                    type="date"
                    variant="outlined"
                    name="date"
                    value={formValues.date}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Notitie"
                    required
                    type="text"
                    variant="outlined"
                    name="note"
                    value={formValues.note}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Selecteer gebruikers</Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="from-user-select">Van gebruiker</InputLabel>
                    <Select
                      labelId="from-user-select"
                      label="Van gebruiker *"
                      value={formValues.fromUserId}
                      name="fromUserId"
                      required
                      onChange={handleInputChange}
                    >
                      <MenuItem value="">
                        <em>Selecteer</em>
                      </MenuItem>
                      {users.map((user) => (
                        <MenuItem value={user.id} key={user.id}>
                          {user.firstName}
                          {user.middleName !== null
                            ? ` ${user.middleName} `
                            : ' '}
                          {user.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Hidden xsDown>
                      <TransferIcon fontSize="large" />
                    </Hidden>
                    <Hidden smUp>
                      <TransferIconMobile fontSize="large" />
                    </Hidden>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="to-user-select">Naar gebruiker</InputLabel>
                    <Select
                      labelId="to-user-select"
                      label="Naar gebruiker *"
                      value={formValues.toUserId}
                      name="toUserId"
                      required
                      onChange={handleInputChange}
                    >
                      <MenuItem value="">
                        <em>Selecteer</em>
                      </MenuItem>
                      {users.map((user) => (
                        <MenuItem value={user.id} key={user.id}>
                          {user.firstName}
                          {user.middleName !== null
                            ? ` ${user.middleName} `
                            : ' '}
                          {user.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={
                      creating ||
                      Object.values(formValues).some((val) => val === '')
                    }
                  >
                    {creating ? 'Aanmaken...' : 'Maak bedrag over'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
