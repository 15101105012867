import { useState } from 'react';

import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';

const initialFormValues = {
  description: '',
  website: '',
  titleTag: '',
  dateTag: '',
  automaticBulk: false,
  automaticBundle: false,
  price: {
    entranceDate: '',
    exitDate: '',
    photo: '',
    video: '',
    broadcast: '',
    paper: '',
    paperSmall: '',
    maximum: '',
    bulk: '',
    bundle: '',
    bundleFactorPhoto: '',
    bundleFactorVideo: '',
  },
};

export function useCreateCustomerForm(): {
  formValues: typeof initialFormValues;
  handleInputChange: (event: any) => void;
  handleSubmit: (event: any) => void;
  submitting: boolean;
} {
  const api = useApi();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [formValues, setFormValues] = useState(_.cloneDeep(initialFormValues));
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormValues((prevFormValues) => {
        const prev = _.cloneDeep(prevFormValues);

        const sub = (prev as any)[parent];

        return {
          ...prev,
          [parent]: {
            ...sub,
            [child]: value,
          },
        };
      });
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    setSubmitting(true);
    try {
      const response = await api.post(
        `/customer`,
        JSON.stringify({
          ...formValues,
          price: {
            ...formValues.price,
            entranceDate:
              formValues.price.entranceDate !== ''
                ? formValues.price.entranceDate
                : null,
            exitDate:
              formValues.price.exitDate !== ''
                ? formValues.price.exitDate
                : null,
          },
        }),
      );

      if (response.data.success === true) {
        dispatch(
          emitFeedback({
            type: FeedbackType.OK,
            message: `${formValues.description} is succesvol toegevoegd!`,
          }),
        );
        history.push('/afnemers');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return {
    formValues,
    handleInputChange,
    handleSubmit,
    submitting,
  };
}
