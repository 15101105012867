import { ReactElement, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  CloudDownloadRounded as DownloadIcon,
  DeleteForeverRounded as DeleteIcon,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useHistory } from 'react-router-dom';

import { MONTHS, formatPrice } from 'common';
import { AsyncTable, PageTitle } from 'components';
import {
  emitFeedback,
  FeedbackType,
  getRoles,
  hasAccess,
  Roles,
} from 'features';
import { useApi, useAppDispatch, useAppSelector } from 'hooks';

export function Creditnotas(): ReactElement {
  const api = useApi();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { session } = useAppSelector((state) => state.auth);

  const [creditnotas, setCreditnotas] = useState<Record<string, any>[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const [users, setUsers] = useState<Record<string, any>[]>([]);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedUser, setSelectedUser] = useState(-1);

  /**
   * A memoized value that remembers the roles of a user
   */
  const userRoles = useMemo(() => {
    if (session?.token === undefined) return [];
    return getRoles(session.token.access);
  }, [session]);

  /**
   * A memoized value that remembers the date of the selected range
   */
  const selectedRange = useMemo(() => {
    if (selectedMonth === -1) {
      const startDate = dayjs()
        .set('month', 0)
        .set('year', selectedYear)
        .startOf('month');
      const endDate = dayjs()
        .set('month', 11)
        .set('year', selectedYear)
        .endOf('month');
      return {
        startDate,
        endDate,
        formatted: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
        },
      };
    }

    const startDate = dayjs()
      .set('month', selectedMonth)
      .set('year', selectedYear)
      .startOf('month');
    const endDate = startDate.endOf('month');

    return {
      startDate,
      endDate,
      formatted: {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      },
    };
  }, [selectedMonth, selectedYear]);

  /**
   * A function that handles the download
   * @param invoiceNumber The invoice number of the creditnota to download
   */
  const handleDownload = async (
    invoiceNumber: number,
    invoiceBusiness: string,
  ) => {
    const response = await api.get(
      `/pdf/creditnota/download?invoiceNumber=${invoiceNumber}`,
      {
        responseType: 'blob',
      },
    );
    try {
      const { data } = response;
      const fileName = `${invoiceNumber} - ${invoiceBusiness}.pdf`;
      saveAs(data, fileName);
    } catch {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message:
            'Er is een fout opgetreden tijdens het downloaden van het document.',
        }),
      );
    }
  };

  /**
   * A function that handles the download
   * @param invoiceNumber The invoice number of the creditnota to download
   */
  const handleDelete = async (invoiceNumber: number) => {
    try {
      await api.delete(`/pdf/creditnota/${invoiceNumber}`);
      history.push('/creditnotas');

      dispatch(
        emitFeedback({
          type: FeedbackType.OK,
          message: 'De creditnota is succesvol verwijderd',
        }),
      );
    } catch {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message:
            'Er is een fout opgetreden tijdens het verwijderen van het document.',
        }),
      );
    }
  };

  /**
   * A function that updates the sales
   */
  const updateCreditnotas = async () => {
    try {
      if (!session?.token.access) return;
      setIsLoaded(false);
      setIsError(false);

      const { startDate, endDate } = selectedRange.formatted;

      const isAdmin = hasAccess([Roles.ADMIN], session.token);

      const query = `?startDate=${startDate}&endDate=${endDate}`;

      let url = '/pdf/creditnota';

      if (!isAdmin) {
        url += `/all-between-dates-userId${query}&userId=${session.user.id}`;
      } else if (selectedUser > -1) {
        url += `/all-between-dates-userId${query}&userId=${selectedUser}`;
      } else {
        url += query;
      }
      const response = await api.get(url);
      setCreditnotas(response.data.data);
      setIsLoaded(true);
    } catch {
      setIsError(true);
    }
  };

  /**
   * An effect that executes data fetching on mount
   */
  useEffect(() => {
    updateCreditnotas();
    const fetchUsers = async () => {
      const response = await api.get('/user/business');
      const userList = response.data.data;
      setUsers(
        userList.sort((a: Record<string, any>, b: Record<string, any>) =>
          a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1,
        ),
      );
      setSelectedUser(-1);
    };
    fetchUsers();
  }, []);

  return (
    <>
      <PageTitle category="Creditnota's" subcategory="Overzicht" />
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        my={3}
      >
        {userRoles.includes(Roles.ADMIN) && (
          <FormControl variant="outlined" size="small">
            <InputLabel>Gebruiker</InputLabel>
            <Select
              native
              label="Gebruiker"
              value={selectedUser}
              onChange={({ target }) =>
                setSelectedUser(parseInt(target.value as string, 10))
              }
            >
              <option value="-1">Alle gebruikers</option>
              {users.map((user) => (
                <option value={user.id} key={user.id}>
                  {user.firstName}
                  {user.middleName !== null ? ` ${user.middleName} ` : ' '}
                  {user.lastName}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        <Box px={1}>
          <FormControl variant="outlined" size="small">
            <InputLabel>Maand</InputLabel>
            <Select
              native
              label="Maand"
              value={selectedMonth}
              onChange={({ target }) =>
                setSelectedMonth(parseInt(target.value as string, 10))
              }
            >
              {MONTHS.map(({ id, month }) => (
                <option key={id} value={id}>
                  {month}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box px={1}>
          <FormControl variant="outlined" size="small">
            <InputLabel>Jaar</InputLabel>
            <Select
              native
              label="Jaar"
              value={selectedYear}
              onChange={({ target }) =>
                setSelectedYear(parseInt(target.value as string, 10))
              }
            >
              {[
                new Date().getFullYear(),
                new Date().getFullYear() - 1,
                new Date().getFullYear() - 2,
                new Date().getFullYear() - 3,
                new Date().getFullYear() - 4,
                new Date().getFullYear() - 5,
                new Date().getFullYear() - 6,
              ].map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box pl={1}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              updateCreditnotas();
            }}
          >
            Toepassen
          </Button>
        </Box>
      </Box>
      <AsyncTable
        columns={[
          'Id',
          'Credit datum',
          'Credit nummer',
          'Totaal',
          'Bedrijf',
          'Acties',
        ]}
        loading={!isLoaded && !isError}
        error={!!isError}
        empty={!!isLoaded && creditnotas.length < 1}
      >
        {creditnotas.map((creditnota: Record<string, any>) => (
          <TableRow key={creditnota.id}>
            <TableCell>#{creditnota.id}</TableCell>
            <TableCell>{creditnota.deliveryDate}</TableCell>
            <TableCell>{creditnota.invoiceNumber}</TableCell>
            <TableCell>{formatPrice(creditnota.amount)}</TableCell>
            <TableCell>{creditnota.user.businessName}</TableCell>
            <TableCell>
              <IconButton
                size="small"
                color="primary"
                onClick={() =>
                  handleDownload(
                    creditnota.invoiceNumber,
                    creditnota.user.businessName,
                  )
                }
              >
                <DownloadIcon />
              </IconButton>
              {userRoles.includes(Roles.ADMIN) && (
                <IconButton
                  disabled
                  size="small"
                  style={{ color: 'red' }}
                  onClick={() => handleDelete(creditnota.id)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </AsyncTable>
    </>
  );
}
