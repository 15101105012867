import { AxiosInstance } from 'axios';

import { ApiResponse } from 'hooks/useApi';

export class CustomerService {
  public static async disableCustomer(
    api: AxiosInstance,
    customerId: number,
  ): Promise<ApiResponse> {
    const response = await api.delete(`/customer/${customerId}`);
    return response?.data;
  }

  public static async enableCustomer(
    api: AxiosInstance,
    customerId: number,
  ): Promise<ApiResponse> {
    const response = await api.patch(`/customer/enable/${customerId}`);
    return response?.data;
  }
}
