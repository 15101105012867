import { useEffect, useState, ReactElement } from 'react';

import { Snackbar, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { clearFeedback, Feedback } from 'features';
import { useAppDispatch, useAppSelector } from 'hooks';

/**
 * A container that displays feedback to the user
 *
 * @returns The `FeedbackDisplay` container
 */
export function FeedbackDisplay(): ReactElement {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { currentFeedback } = useAppSelector((state) => state.feedback);

  const [open, setOpen] = useState(false);
  const [cache, setCache] = useState<Feedback | undefined>();

  /**
   * A function that opens the information window and caches data
   */
  const handleOpen = () => {
    const feedback = { ...currentFeedback };
    if (feedback) {
      setCache(feedback as Feedback);
      setOpen(true);
    }
  };

  /**
   * A function that closes the information window and clears cache
   */
  const handleClose = () => {
    dispatch(clearFeedback());
    setOpen(false);
  };

  /**
   * An effect that handles opening and closing based on the feedback received
   */
  useEffect(() => {
    if (currentFeedback) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [currentFeedback]);

  /**
   * Prevent bug that clears data before item is off the screen
   */
  useEffect(() => {
    let timeout: any;
    if (!open) {
      timeout = setTimeout(
        () => setCache(undefined),
        theme.transitions.duration.leavingScreen,
      );
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [open]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={Boolean(currentFeedback)}
      onClose={(_, reason) => {
        if (reason === 'clickaway') return;
        handleClose();
      }}
      autoHideDuration={(cache?.message.length || 0) * 250}
      disableWindowBlurListener
    >
      <Alert severity={cache?.type}>{cache?.message}</Alert>
    </Snackbar>
  );
}
