import { Box, Button, Container, Grid, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { LogoTitle, PageTitle } from 'components';

export function Export() {
  const history = useHistory();

  return (
    <>
      <PageTitle
        category="Export"
        subcategory="Keuze"
        subtitle="Kies het type bestand om te exporteren"
      />
      <Container maxWidth="xs">
        <Paper>
          <Box p={2}>
            <LogoTitle title="Kies bestandstype" />
            <Box my={2} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push('/export/excel')}
                >
                  Excel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push('/export/pdf')}
                >
                  PDF
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
