import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { LoginDTO } from 'dto';
import { buildSession, emitFeedback, FeedbackType, login } from 'features';
import { useApi, useAppDispatch } from 'hooks';

/**
 * The initial values that are used by the form
 */
const initialValues: LoginDTO = {
  email: '',
  password: '',
};

/**
 * A hook that makes it easier to interact with the login form
 *
 * @returns All actions available for the login form
 */
export function useLoginForm(): {
  formValues: LoginDTO;
  handleInputChange: (event: any) => void;
  handleSubmit: (event: any) => void;
} {
  const api = useApi();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState({ ...initialValues });

  /**
   * A function that handles the change of input
   * @param event The event to use
   */
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  /**
   * A function that submits the form
   * @param event The event to use
   */
  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    try {
      const body: LoginDTO = {
        email: formValues.email,
        password: formValues.password,
      };
      const response = await api.post('/auth/login', JSON.stringify(body));

      if (response.status === 200) {
        dispatch(login(buildSession(response)));
        history.replace('/');
      }
    } catch (error: any) {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message:
            'Deze gegevens zijn niet bekend óf er is een fout opgetreden',
        }),
      );
    }
  };

  return {
    formValues,
    handleInputChange,
    handleSubmit,
  };
}
