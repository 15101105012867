import { ReactElement } from 'react';

import { Box, Typography } from '@material-ui/core';

import useStyles from './LogoTitle.style';

import { LOGO_PATH_SMALL } from 'common';

/**
 * The property types which are used by the `LogoTitle` component
 */
export interface LogoTitleProps {
  title: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  bottomSpacing?: number;
}

/**
 * A component that displays the logo together with a title
 *
 * @param title The title to display
 * @param variant The variant of the text
 *
 * @returns The `LogoTitle` component
 */
export function LogoTitle({
  title,
  variant = 'h4',
  bottomSpacing = 0,
}: LogoTitleProps): ReactElement {
  const styles = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pb={bottomSpacing}
    >
      <Typography variant={variant}>{title}</Typography>
      <img
        src={LOGO_PATH_SMALL}
        alt="112Nederland Logo klein"
        className={styles.image}
      />
    </Box>
  );
}
