import { useState } from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { CreateSaleDTO } from 'dto';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';
import { SaleService } from 'services';

/**
 * The initial values that are used by the form
 */
const initialValues: CreateSaleDTO = {
  title: '',
  link: '',
  date: dayjs().format('YYYY-MM-DD'),
  note: '',
  customerId: -1,
  editorId: -1,
  photographers: [],
};

/**
 * A hook that makes it easier to interact with the create sale form
 *
 * @returns The `useCreateSaleForm` hook
 */
export function useCreateSaleForm(): {
  // generic form
  formValues: CreateSaleDTO;
  handleInputChange: (event: any) => void;
  handleSubmit: (event: any) => void;
  submitting: boolean;

  // photographer
  addPhotographer: (initialId?: number) => void;
  deletePhotographer: (id: number) => void;
  editPhotographer: (index: number, event: any) => void;

  // scraping
  handleScrapeLink: () => Promise<void>;
  scraping: boolean;
} {
  const api = useApi();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [scraping, setScraping] = useState(false);
  const [formValues, setFormValues] = useState<CreateSaleDTO>({
    ...initialValues,
  });

  /**
   * A function that handles the change of input
   * @param event The event to use
   */
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
  };

  /**
   * A function that helps scraping the link and filling in the values
   */
  const handleScrapeLink = async () => {
    if (formValues.link.length < 4) return;
    setScraping(true);
    try {
      const result = await SaleService.scrapeLink(api, formValues.link);

      const updateItems = _.cloneDeep(formValues);

      const isDomain = ['www', 'http'].some((prefix) =>
        result.data.title.includes(prefix),
      );
      if (result.data.title && !isDomain) {
        updateItems.title = result.data.title;
      } else {
        updateItems.title = '';
      }

      if (result.data.date) {
        updateItems.date = result.data.date;
      } else {
        updateItems.date = '';
      }

      if (result.data.customerId) {
        updateItems.customerId = result.data.customerId;
      } else {
        updateItems.customerId = initialValues.customerId;
      }

      setFormValues(updateItems);
    } catch {
      dispatch(
        emitFeedback({
          type: FeedbackType.WARN,
          message: 'Niet alle velden konden automatisch gevuld worden',
        }),
      );
    } finally {
      setScraping(false);
    }
  };

  /**
   * A function that adds an empty photographer field
   */
  const addPhotographer = (initialId = -1): void => {
    const newPhotographer = {
      photoCount: 0,
      videoCount: 0,
      broadcastCount: 0,
      paperSmallCount: 0,
      paperCount: 0,
      userId: initialId,
    };
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      photographers: [...prevFormValues.photographers, newPhotographer],
    }));
  };

  /**
   * A function that deletes a photographer
   * @param index The id of the photographer to delete
   */
  const deletePhotographer = (index: number): void => {
    setFormValues((prevFormValues) => {
      prevFormValues.photographers.splice(index, 1);

      return { ...prevFormValues, photographers: prevFormValues.photographers };
    });
  };

  /**
   * A function that edits a photographer
   * @param index The index of the item
   * @param event The event to use
   */
  const editPhotographer = (index: number, event: any): void => {
    const { value, name } = event.target;
    const currentPhotographers: any = _.cloneDeep(formValues.photographers);
    currentPhotographers[index][name] = parseInt(value, 10);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      photographers: currentPhotographers,
    }));
  };

  /**
   * A function that submits the form
   * @param event The event to use
   */
  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    setSubmitting(true);
    try {
      const response = await SaleService.createSale(api, formValues);
      if (response?.success) {
        dispatch(
          emitFeedback({
            type: FeedbackType.OK,
            message: 'Item succesvol toegevoegd!',
          }),
        );
        history.push('/');
      }
    } catch (error) {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message: `Oeps, er is iets fout gegaan... FOUTCODE: ${error}`,
        }),
      );
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    formValues,
    handleInputChange,
    handleSubmit,
    submitting,

    addPhotographer,
    deletePhotographer,
    editPhotographer,

    handleScrapeLink,
    scraping,
  };
}
