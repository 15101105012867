/* eslint-disable no-await-in-loop */
import { ReactElement, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  GroupRounded as SalesPercentIcon,
  VideocamRounded as VideoPercentIcon,
  CameraRounded as PhotoPercentIcon,
  PersonAddRounded as AddIcon,
  EditRounded as EditIcon,
  DeleteForeverRounded as DeleteIcon,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

import {
  calculateSalePrices,
  formatPrice,
  isPriceNegative,
  MONTHS,
} from 'common';
import { AsyncTable, PageTitle, StatisticCard } from 'components';
import {
  emitFeedback,
  FeedbackType,
  getRoles,
  hasAccess,
  Roles,
} from 'features';
import { useApi, useAppDispatch, useAppSelector } from 'hooks';

const enum ItemTypes {
  Item = 'item',
  Manual = 'manual-amount',
  Bulk = 'bulk',
  Bundle = 'bundle',
}

/**
 * A view that displays the sales
 *
 * @returns The `ListSales` view
 */
export function ListSales(): ReactElement {
  const api = useApi();
  const dispatch = useAppDispatch();

  const { session } = useAppSelector((state) => state.auth);

  const [sales, setSales] = useState<Record<string, any>[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const [users, setUsers] = useState<Record<string, any>[]>([]);

  const [salesStats, setSalesStats] = useState({
    myItemAmount: 0,
    mySales: 0,
    totalItemAmount: 0,
    totalSales: 0,
  });

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedUser, setSelectedUser] = useState(-2);

  const [open, setOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(-1);
  const [bulkToDelete, setBulkToDelete] = useState(-1);
  const [manualToDelete, setManualToDelete] = useState(-1);

  const iff = (condition: any, then: any, otherwise: any) =>
    condition ? then : otherwise;

  /**
   * A memoized value that remembers the roles of a user
   */
  const userRoles = useMemo(() => {
    if (session?.token === undefined) return [];
    return getRoles(session.token.access);
  }, [session]);

  /**
   * A memoized value that remembers the date of the selected range
   */
  const selectedRange = useMemo(() => {
    if (selectedMonth === -1) {
      const startDate = dayjs()
        .set('month', 0)
        .set('year', selectedYear)
        .startOf('month');
      const endDate = dayjs()
        .set('month', 11)
        .set('year', selectedYear)
        .endOf('month');
      return {
        startDate,
        endDate,
        formatted: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
        },
      };
    }

    const startDate = dayjs()
      .set('month', selectedMonth)
      .set('year', selectedYear)
      .startOf('month');
    const endDate = startDate.endOf('month');

    return {
      startDate,
      endDate,
      formatted: {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      },
    };
  }, [selectedMonth, selectedYear]);

  /**
   * A function that updates the sales
   */
  const updateSales = async () => {
    try {
      if (!session?.token.access) return;
      setIsLoaded(false);
      setIsError(false);

      const { startDate, endDate } = selectedRange.formatted;

      const isAdmin = hasAccess([Roles.ADMIN], session.token);

      const query = `?startDate=${startDate}&endDate=${endDate}`;

      let url = '/item';

      // setSelectedUser(session.user.id);

      if (!isAdmin) {
        url += `/between-dates-userid${query}&userId=${session.user.id}`;
      } else if (selectedUser === -1) {
        url += query;
      } else if (selectedUser > -1) {
        url += `/between-dates-userid${query}&userId=${selectedUser}`;
      } else {
        setSelectedUser(session.user.id);
        url += `/between-dates-userid${query}&userId=${session.user.id}`;
      }

      const response = await api.get(url);
      setSales(response.data.data);
      setIsLoaded(true);
    } catch {
      setIsError(true);
    }
  };

  /**
   * A function that updates the statistics
   */
  const updateStats = async () => {
    try {
      let myItemAmount = 0;
      let mySales = 0;
      let totalItemAmount = 0;
      let totalSales = 0;
      let userId;

      setSalesStats({
        myItemAmount: 0,
        mySales: 0,
        totalItemAmount: 0,
        totalSales: 0,
      });

      const { startDate, endDate } = selectedRange.formatted;

      if (selectedUser === -2) {
        userId = Number(session?.user.id);
      } else {
        userId = selectedUser;
      }

      if (userRoles.includes(Roles.ADMIN)) {
        const urls = ['total-number-of-sales', 'total-price-of-selling'];

        // Total Number Of Sales
        const resultTotalNumberSales = await api.get(
          `statistics/${urls[0]}?startDate=${startDate}&endDate=${endDate}`,
        );
        totalItemAmount = resultTotalNumberSales.data.data.result;

        // Total Price Of Selling
        const resultTotalPriceOfSelling = await api.get(
          `statistics/${urls[1]}?startDate=${startDate}&endDate=${endDate}`,
        );
        totalSales = resultTotalPriceOfSelling.data.data.result;
      }

      const urls = ['number-of-sales', 'price-of-selling'];

      // Number Of Sales
      const resultNumberSales = await api.get(
        `statistics/${urls[0]}?startDate=${startDate}&endDate=${endDate}&userId=${userId}`,
      );
      myItemAmount = resultNumberSales.data.data.result;

      // Price Of Selling
      const resultPriceOfSelling = await api.get(
        `statistics/${urls[1]}?startDate=${startDate}&endDate=${endDate}&userId=${userId}`,
      );
      mySales = resultPriceOfSelling.data.data.result;

      setSalesStats({
        ...salesStats,
        myItemAmount,
        mySales,
        totalItemAmount,
        totalSales,
      });
    } catch {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message:
            'Er is een fout opgetreden tijdens het ophalen van de statistieken...',
        }),
      );
    }
  };

  const handleClickOpen = (e: any, itemId: number, type: string) => {
    if (type === ItemTypes.Item) {
      setItemToDelete(itemId);
      setOpen(true);
    }

    if (type === ItemTypes.Bulk) {
      setBulkToDelete(itemId);
      setOpen(true);
    }

    if (type === ItemTypes.Manual) {
      setManualToDelete(itemId);
      setOpen(true);
    }
  };

  const handleDeleteClose = async () => {
    if (selectedUser === -1 && itemToDelete !== -1) {
      try {
        const response = await api.delete(`/item/${itemToDelete}`);
        if (response.data.success) {
          dispatch(
            emitFeedback({
              type: FeedbackType.OK,
              message: 'Item succesvol verwijderd!',
            }),
          );
        }
      } catch (error) {
        dispatch(
          emitFeedback({
            type: FeedbackType.ERROR,
            message: `Er is iets fout gegaan: ${error}`,
          }),
        );
      }
    } else if (
      selectedUser === -2 &&
      itemToDelete !== -1 &&
      session?.user.id !== undefined
    ) {
      try {
        const response = await api.delete(
          `item/from-user/${session.user.id}/item/${itemToDelete}`,
        );
        if (response.data.success) {
          dispatch(
            emitFeedback({
              type: FeedbackType.OK,
              message: 'Item succesvol verwijderd!',
            }),
          );
        }
      } catch (error) {
        dispatch(
          emitFeedback({
            type: FeedbackType.ERROR,
            message: `Er is iets fout gegaan: ${error}`,
          }),
        );
      }
    } else if (itemToDelete !== -1) {
      try {
        const response = await api.delete(
          `item/from-user/${selectedUser}/item/${itemToDelete}`,
        );
        if (response.data.success) {
          dispatch(
            emitFeedback({
              type: FeedbackType.OK,
              message: 'Item succesvol verwijderd!',
            }),
          );
        }
      } catch (error) {
        dispatch(
          emitFeedback({
            type: FeedbackType.ERROR,
            message: `Er is iets fout gegaan: ${error}`,
          }),
        );
      }
    }

    if (selectedUser === -1 && manualToDelete !== -1) {
      try {
        const response = await api.delete(`/manual-amount/${manualToDelete}`);
        if (response.data.success) {
          dispatch(
            emitFeedback({
              type: FeedbackType.OK,
              message: 'Handmatig bedrag succesvol verwijderd!',
            }),
          );
        }
      } catch (error) {
        dispatch(
          emitFeedback({
            type: FeedbackType.ERROR,
            message: `Er is iets fout gegaan: ${error}`,
          }),
        );
      }
    }

    if (selectedUser === -1 && bulkToDelete !== -1) {
      try {
        const response = await api.delete(`/bulk/${bulkToDelete}`);
        if (response.data.success) {
          dispatch(
            emitFeedback({
              type: FeedbackType.OK,
              message: 'Bulk succesvol verwijderd!',
            }),
          );
        }
      } catch (error) {
        dispatch(
          emitFeedback({
            type: FeedbackType.ERROR,
            message: `Er is iets fout gegaan: ${error}`,
          }),
        );
      }
    }

    updateSales();
    updateStats();
    setOpen(false);
  };

  const handleClose = () => {
    setItemToDelete(-1);
    setBulkToDelete(-1);
    setManualToDelete(-1);
    setOpen(false);
  };

  /**
   * An effect that executes data fetching on mount
   */
  useEffect(() => {
    updateSales();
    updateStats();
    const fetchUsers = async () => {
      const response = await api.get('/user/for-item');
      const userList = response.data.data;
      setUsers(
        userList.sort((a: Record<string, any>, b: Record<string, any>) =>
          a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1,
        ),
      );
      // setSelectedUser(-2);
    };
    fetchUsers();
  }, []);

  return (
    <>
      <PageTitle category="Verkopen" subcategory="Overzicht" />

      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <StatisticCard
            text={salesStats.myItemAmount.toString()}
            description="Mijn verkochte items"
            enableText
            hideTextBtn
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <StatisticCard
            text={formatPrice(salesStats.mySales)}
            description="Mijn totale verkoop"
            enableText
            hideTextBtn
          />
        </Grid>
        {userRoles.includes(Roles.ADMIN) && (
          <Grid item xs={12} sm={6} lg={3}>
            <StatisticCard
              text={salesStats.totalItemAmount.toString()}
              description="Totale items"
              enableText
              hideTextBtn
            />
          </Grid>
        )}
        {userRoles.includes(Roles.ADMIN) && (
          <Grid item xs={12} sm={6} lg={3}>
            <StatisticCard
              text={formatPrice(salesStats.totalSales)}
              description="Totale omzet"
              enableText={false}
              hideTextBtn={false}
            />
          </Grid>
        )}
      </Grid>
      <Box display="flex" justifyContent="flex-end" my={2}>
        <NavLink to="/verkopen/nieuw">
          <Button variant="contained" color="primary" size="large">
            Verkoop toevoegen <AddIcon />
          </Button>
        </NavLink>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        my={3}
      >
        {userRoles.includes(Roles.ADMIN) && (
          <FormControl variant="outlined" size="small">
            <InputLabel>Gebruiker</InputLabel>
            <Select
              native
              label="Gebruiker"
              value={selectedUser}
              onChange={({ target }) =>
                setSelectedUser(parseInt(target.value as string, 10))
              }
            >
              <option value="-1">Alle gebruikers</option>
              {users.map((user) => (
                <option value={user.id} key={user.id}>
                  {user.firstName}
                  {user.middleName !== null ? ` ${user.middleName} ` : ' '}
                  {user.lastName}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        <Box px={1}>
          <FormControl variant="outlined" size="small">
            <InputLabel>Maand</InputLabel>
            <Select
              native
              label="Maand"
              value={selectedMonth}
              onChange={({ target }) =>
                setSelectedMonth(parseInt(target.value as string, 10))
              }
            >
              {MONTHS.map(({ id, month }) => (
                <option key={id} value={id}>
                  {month}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box px={1}>
          <FormControl variant="outlined" size="small">
            <InputLabel>Jaar</InputLabel>
            <Select
              native
              label="Jaar"
              value={selectedYear}
              onChange={({ target }) =>
                setSelectedYear(parseInt(target.value as string, 10))
              }
            >
              {[
                new Date().getFullYear(),
                new Date().getFullYear() - 1,
                new Date().getFullYear() - 2,
                new Date().getFullYear() - 3,
                new Date().getFullYear() - 4,
                new Date().getFullYear() - 5,
                new Date().getFullYear() - 6,
              ].map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box pl={1}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              updateSales();
              updateStats();
            }}
          >
            Toepassen
          </Button>
        </Box>
      </Box>
      <AsyncTable
        columns={[
          'Datum',
          'Referentie',
          'Afnemer',
          'Berekening',
          'Bruto',
          'Percentage',
          'Totaalprijs',
          'Acties',
        ]}
        loading={!isLoaded && !isError}
        error={!!isError}
        empty={!!isLoaded && sales.length < 1}
      >
        {sales.map((sale: Record<string, any>) => {
          if (sale.type === ItemTypes.Item) {
            const saleCalculation = calculateSalePrices(sale);

            return (
              <TableRow key={sale.id}>
                <TableCell>{dayjs(sale.date).format('D MMMM, YYYY')}</TableCell>
                <TableCell>
                  <a href={sale.link} target="_blank" rel="noopener noreferrer">
                    {sale.title}
                  </a>
                </TableCell>
                <TableCell>{sale.customers.description}</TableCell>
                <TableCell>
                  {saleCalculation.map((saleItem: Record<string, any>) => {
                    const { counts, basePrices } = saleItem.calculation;
                    if (!sale.bulk) {
                      return (
                        <Box
                          key={`${saleItem.user}_calculation_${sale.id}`}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          flexDirection="column"
                        >
                          {saleCalculation.length > 1 && (
                            <Typography variant="body2">
                              <Box fontWeight="bold">{`${saleItem.user}`}</Box>
                            </Typography>
                          )}
                          {counts.photo > 0 && (
                            <Typography variant="overline">
                              {`Foto:  ${counts.photo}× ${formatPrice(
                                basePrices.photo,
                              )}`}
                            </Typography>
                          )}
                          {counts.video > 0 && (
                            <Typography variant="overline">
                              {`Video:  ${counts.video}× ${formatPrice(
                                basePrices.video,
                              )}`}
                            </Typography>
                          )}
                          {counts.broadcast > 0 && (
                            <Typography variant="overline">
                              {`Uitzending:  ${counts.broadcast}× ${formatPrice(
                                basePrices.broadcast,
                              )}`}
                            </Typography>
                          )}
                          {counts.paper > 0 && (
                            <Typography variant="overline">
                              {`Krant:  ${counts.paper}× ${formatPrice(
                                basePrices.paper,
                              )}`}
                            </Typography>
                          )}
                          {counts.paperSmall > 0 && (
                            <Typography variant="overline">
                              {`Kleine Krant:  ${
                                counts.paperSmall
                              }× ${formatPrice(basePrices.paperSmall)}`}
                            </Typography>
                          )}
                        </Box>
                      );
                    }
                    return (
                      <Box
                        key={`${saleItem.user}_calculation_${sale.id}`}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                      >
                        {saleCalculation.length > 1 && (
                          <Typography variant="body2">
                            <Box fontWeight="bold">{`${saleItem.user}`}</Box>
                          </Typography>
                        )}
                        {counts.photo > 0 && (
                          <Typography variant="overline">
                            {`Foto:  ${counts.photo}×`}
                          </Typography>
                        )}
                        {counts.video > 0 && (
                          <Typography variant="overline">
                            {`Video:  ${counts.video}× `}
                          </Typography>
                        )}
                        {counts.broadcast > 0 && (
                          <Typography variant="overline">
                            {`Uitzending:  ${counts.broadcast}×`}
                          </Typography>
                        )}
                        {counts.paper > 0 && (
                          <Typography variant="overline">
                            {`Krant:  ${counts.paper}×`}
                          </Typography>
                        )}
                        {counts.paperSmall > 0 && (
                          <Typography variant="overline">
                            {`Kleine Krant:  ${counts.paperSmall}×`}
                          </Typography>
                        )}
                      </Box>
                    );
                  })}
                </TableCell>
                <TableCell>
                  {saleCalculation.map((saleItem: Record<string, any>) => {
                    const { totals } = saleItem.calculation;

                    if (sale.bundle) {
                      return (
                        <Box
                          key={`${saleItem.user}_calculation4_${sale.id}`}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          flexDirection="column"
                        >
                          <Typography variant="body2" color="primary">
                            <Box fontWeight="bold">BUNDEL</Box>
                          </Typography>
                        </Box>
                      );
                    }
                    if (!sale.bulk) {
                      return (
                        <Box
                          key={`${saleItem.user}_calculation2_${saleItem.user.id}`}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          flexDirection="column"
                        >
                          {saleCalculation.length > 1 && (
                            <Typography variant="body2">
                              <Box fontWeight="bold">{`${saleItem.user}`}</Box>
                            </Typography>
                          )}
                          <Typography variant="overline">
                            {Number(totals.max) !== 0
                              ? `${formatPrice(Number(totals.max))} (max) `
                              : `${formatPrice(Number(totals.total))}`}
                          </Typography>
                        </Box>
                      );
                    }

                    return (
                      <Box
                        key={`${saleItem.user}_calculation4_${sale.id}`}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                      >
                        <Typography variant="body2" color="primary">
                          <Box fontWeight="bold">BULK</Box>
                        </Typography>
                      </Box>
                    );
                  })}
                </TableCell>
                <TableCell>
                  {saleCalculation.map((saleItem: Record<string, any>) => {
                    const { percentages, counts, totals } =
                      saleItem.calculation;

                    if (!sale.bulk) {
                      return (
                        <Box
                          key={`${saleItem.user}_calculation3_${sale.id}`}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          flexDirection="column"
                        >
                          {counts.photo > 0 && (
                            <Typography variant="overline">
                              <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <PhotoPercentIcon fontSize="small" />
                                &nbsp;
                                {`${percentages.photo}%`}
                              </Box>
                            </Typography>
                          )}
                          {counts.paper > 0 && (
                            <Typography variant="overline">
                              <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <PhotoPercentIcon fontSize="small" />
                                &nbsp;
                                {`${percentages.photo}%`}
                              </Box>
                            </Typography>
                          )}
                          {counts.papersmall > 0 && (
                            <Typography variant="overline">
                              <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <PhotoPercentIcon fontSize="small" />
                                &nbsp;
                                {`${percentages.photo}%`}
                              </Box>
                            </Typography>
                          )}
                          {counts.video > 0 && (
                            <Typography variant="overline">
                              <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <VideoPercentIcon fontSize="small" />
                                &nbsp;
                                {`${percentages.video}%`}
                              </Box>
                            </Typography>
                          )}
                          {counts.broadcast > 0 && (
                            <Typography variant="overline">
                              <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <VideoPercentIcon fontSize="small" />
                                &nbsp;
                                {`${percentages.video}%`}
                              </Box>
                            </Typography>
                          )}
                          {Number(totals.max) === 0 && (
                            <Typography variant="overline">
                              <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <SalesPercentIcon fontSize="small" />
                                &nbsp;
                                {`${percentages.sales}%`}
                              </Box>
                            </Typography>
                          )}
                        </Box>
                      );
                    }
                    return <></>;
                  })}
                </TableCell>
                <TableCell>
                  {saleCalculation.map((saleItem: Record<string, any>) => {
                    const { profits } = saleItem.calculation;
                    if (sale.bundle) {
                      return (
                        <Box
                          key={`${saleItem.user}_calculation4_${sale.id}`}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          flexDirection="column"
                        >
                          <Typography variant="body2" color="primary">
                            <Box fontWeight="bold">BUNDEL</Box>
                          </Typography>
                        </Box>
                      );
                    }

                    if (!sale.bulk) {
                      return (
                        <Box
                          key={`${saleItem.user}_calculation4_${sale.id}`}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          flexDirection="column"
                        >
                          {saleCalculation.length > 1 && (
                            <Typography variant="body2">
                              <Box fontWeight="bold">{`${saleItem.user}`}</Box>
                            </Typography>
                          )}
                          <Typography
                            variant="overline"
                            style={{
                              color: isPriceNegative(profits.total)
                                ? 'red'
                                : 'green',
                            }}
                          >
                            {`${formatPrice(profits.total)}`}
                          </Typography>
                        </Box>
                      );
                    }

                    return (
                      <Box
                        key={`${saleItem.user}_calculation4_${sale.id}`}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                      >
                        <Typography variant="body2" color="primary">
                          <Box fontWeight="bold">BULK</Box>
                        </Typography>
                      </Box>
                    );
                  })}
                </TableCell>
                <TableCell>
                  <NavLink to={`/verkopen/bewerken/${sale.id}`}>
                    <IconButton size="small">
                      <EditIcon color="action" />
                    </IconButton>
                  </NavLink>
                  <IconButton
                    size="small"
                    onClick={(e) => handleClickOpen(e, sale.id, ItemTypes.Item)}
                  >
                    <DeleteIcon style={{ color: 'red' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          }
          if (sale.type === ItemTypes.Manual) {
            return (
              <TableRow key={sale.id}>
                <TableCell>{dayjs(sale.date).format('D MMMM, YYYY')}</TableCell>
                <TableCell>{sale.note}</TableCell>
                <TableCell>
                  {sale.fromUser.firstName}
                  {sale.fromUser.middleName !== null
                    ? ` ${sale.fromUser.middleName} `
                    : ' '}
                  {sale.fromUser.lastName}
                  {' -> '}
                  {sale.toUser.firstName}
                  {sale.toUser.middleName !== null
                    ? ` ${sale.toUser.middleName} `
                    : ' '}
                  {sale.toUser.lastName}
                </TableCell>
                <TableCell />
                <TableCell>
                  {sale.fromUser.id === selectedUser
                    ? `${formatPrice(sale.amount * -1)}`
                    : `${formatPrice(sale.amount)}`}
                </TableCell>
                <TableCell />
                <TableCell>
                  <Typography
                    variant="overline"
                    style={{
                      color: iff(
                        sale.fromUser.id === selectedUser,
                        isPriceNegative(sale.amount * -1) ? 'red' : 'green',
                        isPriceNegative(sale.amount) ? 'red' : 'green',
                      ),
                    }}
                  >
                    {sale.fromUser.id === selectedUser
                      ? `${formatPrice(sale.amount * -1)}`
                      : `${formatPrice(sale.amount)}`}
                  </Typography>
                </TableCell>
                <TableCell>
                  {userRoles.includes(Roles.ADMIN) && (
                    <IconButton
                      size="small"
                      onClick={(e) =>
                        handleClickOpen(e, sale.id, ItemTypes.Manual)
                      }
                    >
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          }
          if (sale.type === ItemTypes.Bulk) {
            return (
              <TableRow key={sale.id}>
                <TableCell>{dayjs(sale.date).format('D MMMM, YYYY')}</TableCell>
                <TableCell>{sale.note}</TableCell>
                <TableCell>{sale.customers.description}</TableCell>
                <TableCell>
                  {sale.bulkUsers.map((bulkItem: Record<string, any>) => (
                    <Box
                      key={bulkItem.user.id}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      {sale.bulkUsers.length > 1 && (
                        <Typography variant="body2">
                          <Box fontWeight="bold">{bulkItem.user.firstName}</Box>
                        </Typography>
                      )}

                      <Typography variant="overline">
                        {`Verkopen:  ${bulkItem.amountItems}x`}
                      </Typography>
                    </Box>
                  ))}
                </TableCell>
                <TableCell>
                  {sale.bulkUsers.map((bulkItem: Record<string, any>) => (
                    <Box
                      key={bulkItem.user.id}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      {sale.bulkUsers.length > 1 && (
                        <Typography variant="body2">
                          <Box fontWeight="bold">{bulkItem.user.firstName}</Box>
                        </Typography>
                      )}

                      <Typography variant="overline">
                        {`${formatPrice(bulkItem.totalPrice)}`}
                      </Typography>
                    </Box>
                  ))}
                </TableCell>
                <TableCell>
                  {sale.bulkUsers.map((bulkItem: Record<string, any>) => (
                    <Box
                      key={bulkItem.user.id}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      {sale.bulkUsers.length > 1 && (
                        <Typography variant="body2">
                          <Box fontWeight="bold">{`${bulkItem.user.firstName}`}</Box>
                        </Typography>
                      )}

                      <Typography variant="overline">
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <SalesPercentIcon fontSize="small" />
                          &nbsp;
                          {`${bulkItem.userBulkPercentage}%`}
                        </Box>
                      </Typography>
                    </Box>
                  ))}
                </TableCell>
                <TableCell>
                  {sale.bulkUsers.map((bulkItem: Record<string, any>) => (
                    <Box
                      key={bulkItem.user.id}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      {sale.bulkUsers.length > 1 && (
                        <Typography variant="body2">
                          <Box fontWeight="bold">{bulkItem.user.firstName}</Box>
                        </Typography>
                      )}
                      <Typography
                        variant="overline"
                        style={{
                          color: isPriceNegative(
                            (bulkItem.totalPrice / 100) *
                              bulkItem.userBulkPercentage,
                          )
                            ? 'red'
                            : 'green',
                        }}
                      >
                        {`${formatPrice(
                          (bulkItem.totalPrice / 100) *
                            bulkItem.userBulkPercentage,
                        )}`}
                      </Typography>
                    </Box>
                  ))}
                </TableCell>
                <TableCell>
                  {userRoles.includes(Roles.ADMIN) && (
                    <IconButton
                      size="small"
                      onClick={(e) =>
                        handleClickOpen(e, sale.id, ItemTypes.Bulk)
                      }
                    >
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          }
          if (sale.type === ItemTypes.Bundle) {
            return (
              <TableRow key={sale.id}>
                <TableCell>{dayjs(sale.date).format('D MMMM, YYYY')}</TableCell>
                <TableCell>{sale.note}</TableCell>
                <TableCell>{sale.customers.description}</TableCell>
                <TableCell>
                  {sale.bundleUsers.map((bundleItem: Record<string, any>) => (
                    <Box
                      key={bundleItem.user.id}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      {sale.bundleUsers.length > 1 && (
                        <Typography variant="body2">
                          <Box fontWeight="bold">
                            {bundleItem.user.firstName}
                          </Box>
                        </Typography>
                      )}

                      <Typography variant="overline">
                        <Box>{`Foto's:  ${
                          bundleItem.amountPhotos
                        }x ${formatPrice(
                          bundleItem.totalPricePhotos / bundleItem.amountPhotos,
                        )}`}</Box>
                        <Box>{`Video's:  ${
                          bundleItem.amountVideos
                        }x ${formatPrice(
                          bundleItem.totalPriceVideos / bundleItem.amountVideos,
                        )}`}</Box>
                      </Typography>
                    </Box>
                  ))}
                </TableCell>
                <TableCell>
                  {sale.bundleUsers.map((bundleItem: Record<string, any>) => (
                    <Box
                      key={bundleItem.user.id}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      {sale.bundleUsers.length > 1 && (
                        <Typography variant="body2">
                          <Box fontWeight="bold">
                            {bundleItem.user.firstName}
                          </Box>
                        </Typography>
                      )}

                      <Typography variant="overline">
                        {`${formatPrice(
                          Number(bundleItem.totalPriceVideos) +
                            Number(bundleItem.totalPricePhotos),
                        )}`}
                      </Typography>
                    </Box>
                  ))}
                </TableCell>
                <TableCell>
                  {sale.bundleUsers.map((bundleItem: Record<string, any>) => (
                    <Box
                      key={bundleItem.user.id}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      {sale.bundleUsers.length > 1 && (
                        <Typography variant="body2">
                          <Box fontWeight="bold">{`${bundleItem.user.firstName}`}</Box>
                        </Typography>
                      )}

                      {bundleItem.amountPhotos > 0 && (
                        <Typography variant="overline">
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <PhotoPercentIcon fontSize="small" />
                            &nbsp;
                            {`${bundleItem.userPhotoPercentage}%`}
                          </Box>
                        </Typography>
                      )}
                      {bundleItem.amountVideos > 0 && (
                        <Typography variant="overline">
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <VideoPercentIcon fontSize="small" />
                            &nbsp;
                            {`${bundleItem.userVideoPercentage}%`}
                          </Box>
                        </Typography>
                      )}
                    </Box>
                  ))}
                </TableCell>
                <TableCell>
                  {sale.bundleUsers.map((bundleItem: Record<string, any>) => (
                    <Box
                      key={bundleItem.user.id}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      {sale.bundleUsers.length > 1 && (
                        <Typography variant="body2">
                          <Box fontWeight="bold">{`${bundleItem.user.firstName}`}</Box>
                        </Typography>
                      )}

                      <Typography
                        variant="overline"
                        style={{
                          color: isPriceNegative(
                            Number(bundleItem.totalPricePhotos) +
                              Number(bundleItem.totalPriceVideos),
                          )
                            ? 'red'
                            : 'green',
                        }}
                      >
                        {`${formatPrice(
                          Number(bundleItem.totalPricePhotos) *
                            (Number(bundleItem.userPhotoPercentage) / 100) +
                            Number(bundleItem.totalPriceVideos) *
                              (Number(bundleItem.userVideoPercentage) / 100),
                        )}`}
                      </Typography>
                    </Box>
                  ))}
                </TableCell>
                <TableCell />
              </TableRow>
            );
          }

          return null;
        })}
      </AsyncTable>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Weet je zeker dat je het wilt verwijderen?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zodra je op de knop verwijder drukt is er geen weg meer terug!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuleer</Button>
          <Button
            onClick={handleDeleteClose}
            style={{ color: 'red' }}
            autoFocus
          >
            Verwijder
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
