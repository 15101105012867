import { darken, lighten, makeStyles } from '@material-ui/core';

import { DRAWER_WIDTH_DENSE, DRAWER_WIDTH_EXPANDED } from 'common';

/**
 * The styles which are used by the `Sidebar` component
 */
export default makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH_EXPANDED,
  },
  drawerClosed: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH_DENSE,
    },
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: DRAWER_WIDTH_DENSE,
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: DRAWER_WIDTH_EXPANDED,
  },
  drawerPaper: {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  navItem: {},
  navItemActive: {
    '& div span, & div svg': {
      color: theme.palette.primary.main,
    },
  },
  navItemIcon: {
    '& svg': {
      color: darken(theme.palette.primary.main, 0.5),
    },
  },
  navItemText: {
    '& span': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  },
}));
