import { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { populateNull } from 'common';
import { EditUserDTO } from 'dto';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';
import { AuthService, UserService } from 'services';

/**
 * The initial values that are used by the form
 */
const initialValues: EditUserDTO = {
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  photoPercentage: 0,
  videoPercentage: 0,
  vatPercentage: 21,
  isBusiness: false,
  sendingHelpPercentage: 0,
  bulkPercentage: 0,
  isEditor: false,
  vat: '',
  coc: '',
  iban: '',
  businessName: '',
  street: '',
  housenumber: '',
  zipcode: '',
  place: '',
  isThirdParty: false,
};

/**
 * A hook that makes it easier to interact with the edit user form
 *
 * @param userId The id of the user which should be edited
 *
 * @returns The `useEditUserForm` hook
 */
export function useEditUserForm(userId: number): {
  formValues: EditUserDTO;
  handleInputChange: (event: any, callback?: any) => void;
  handleSubmit: (event: any, type: 'email' | 'profile') => void;
  submitting: boolean;
  fetching: boolean;
} {
  const api = useApi();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [initialEmail, setInitialEmail] = useState<string | undefined>();
  const [formValues, setFormValues] = useState<EditUserDTO>({
    ...initialValues,
  });

  /**
   * A function thet fetches and sets the user data
   * @param userId The id of the user to fetch
   */
  const fetchUserData = useCallback(async () => {
    setFetching(true);
    const response = await api.get(`/user/admin/account/${userId}`);
    setFetching(false);
    setFormValues(populateNull(response.data.data));
    setInitialEmail(response.data.data.email);
  }, [userId]);

  /**
   * A function that handles the change of input
   * @param event The event to use
   */
  const handleInputChange = (event: any, callback?: any) => {
    const { name, value } = event.target;
    if (callback) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: callback ? callback(value) : callback,
      }));
    } else {
      setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
    }
  };

  /**
   * A function that submits the form
   * @param event The event to use
   */
  const handleSubmit = useCallback(
    async (event: any, type: 'email' | 'profile') => {
      event?.preventDefault();
      setSubmitting(true);
      try {
        if (type === 'profile') {
          const response = await UserService.editUserAdmin(
            api,
            userId,
            formValues,
          );

          if (response?.success) {
            dispatch(
              emitFeedback({
                type: FeedbackType.OK,
                message: `${formValues.firstName} is succesvol bewerkt!`,
              }),
            );
            history.push('/gebruikers');
          }
        }
        if (type === 'email') {
          if (initialEmail === undefined) {
            throw new Error('Initial email was not set!');
          }

          const response = await AuthService.changeEmail(
            api,
            initialEmail,
            formValues.email,
            userId,
          );

          if (response?.success) {
            dispatch(
              emitFeedback({
                type: FeedbackType.OK,
                message: `Email is succesvol verzonden naar ${formValues.email}`,
              }),
            );
            history.push('/gebruikers');
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [userId, formValues, initialEmail],
  );

  /**
   * An effect which handles the loading of user data
   */
  useEffect(() => {
    fetchUserData();
  }, [userId]);

  return { formValues, handleInputChange, handleSubmit, submitting, fetching };
}
