import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  CheckCircle as EnableIcon,
  Block as DeleteIcon,
  EditRounded as EditIcon,
  NotInterestedRounded as InactiveIcon,
  PersonAddRounded as AddIcon,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

import { formatPrice } from 'common';
import { AsyncTable, PageTitle, TableModal } from 'components';
import { ReadCustomerDTO } from 'dto';
import { useApi } from 'hooks';
import { CustomerService } from 'services';

export function ListCustomers(): ReactElement {
  const api = useApi();

  const [customers, setCustomers] = useState<ReadCustomerDTO[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const [selectedPrices, setSelectedPrices] = useState<ReadCustomerDTO>();

  /**
   * If the price modal should be open
   */
  const priceModalOpen = Boolean(selectedPrices);

  /**
   * A function to handle the loading of customers
   */
  const handleLoadCustomers = async () => {
    try {
      setCustomers([]);
      setIsLoaded(false);
      setIsError(false);

      const response = await api.get('/customer');
      setCustomers(response.data.data);
      setIsLoaded(true);
    } catch {
      setIsError(true);
    }
  };

  /**
   * A function that disabled a customer based on id
   * @param customerId The id of the customer to disable
   */
  const handleDisable = async (customerId: number) => {
    const selection = confirm(
      'Weet u zeker dat u deze afnemer wilt deactiveren?',
    );
    if (selection === true) {
      await CustomerService.disableCustomer(api, customerId);
      handleLoadCustomers();
    }
  };

  /**
   * A function that handles the enabling of a customer
   * @param customerId The id of the customer
   */
  const handleEnable = async (customerId: number) => {
    const selection = confirm(
      'Weet u zeker dat u deze afnemer weer wilt activeren?',
    );
    if (selection === true) {
      await CustomerService.enableCustomer(api, customerId);
      handleLoadCustomers();
    }
  };

  /**
   * An effect that runs on page load
   */
  useEffect(() => {
    handleLoadCustomers();
  }, []);

  return (
    <>
      <TableModal
        title="Tarieven"
        open={priceModalOpen}
        onClose={() => setSelectedPrices(undefined)}
        columns={['Categorie', 'Tarief']}
        rows={[
          {
            category: 'Foto',
            value: formatPrice(selectedPrices?.price.photo || 0),
          },
          {
            category: 'Video',
            value: formatPrice(selectedPrices?.price.video || 0),
          },
          {
            category: 'Krant',
            value: formatPrice(selectedPrices?.price.paper || 0),
          },
          {
            category: 'Krant (klein)',
            value: formatPrice(selectedPrices?.price.paperSmall || 0),
          },
          {
            category: 'Uitzending',
            value: formatPrice(selectedPrices?.price.broadcast || 0),
          },
          {
            category: 'Maximum',
            value: formatPrice(selectedPrices?.price.maximum || 0),
          },
          {
            category: 'Bulk',
            value: formatPrice(selectedPrices?.price.bulk || 0),
          },
          {
            category: 'Bundel',
            value: formatPrice(selectedPrices?.price.bundle || 0),
          },
        ]}
      />
      <PageTitle category="Afnemers" subcategory="Overzicht" />
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <NavLink to="/afnemers/nieuw">
          <Button variant="contained" color="primary" size="large">
            Afnemer toevoegen <AddIcon />
          </Button>
        </NavLink>
      </Box>
      <Box minHeight={400}>
        <AsyncTable
          columns={[
            '#',
            'Naam',
            'Website',
            'Laatste datum',
            'Ondersteunt bulk',
            'Tarieven',
            'Acties',
          ]}
          loading={!isLoaded && !isError}
          error={!!isError}
          empty={!!isLoaded && customers.length < 1}
          striped
        >
          {customers.map((customer, index) => {
            const { disabled } = customer;

            return (
              <TableRow
                key={customer.id}
                style={{ opacity: customer.disabled ? 0.5 : 1 }}
              >
                <TableCell>
                  {customer.id}
                  {disabled && (
                    <IconButton disabled>
                      <InactiveIcon color="action" />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>{customer.description}</TableCell>
                <TableCell>
                  <a
                    href={customer.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {customer.website}
                  </a>
                </TableCell>
                <TableCell>
                  {dayjs(customer.price.entranceDate).format('D MMMM, YYYY')}
                </TableCell>
                <TableCell>
                  <Checkbox
                    disableRipple
                    checked={customer.automaticBulk}
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setSelectedPrices(customers[index]);
                    }}
                  >
                    Bekijk tarieven
                  </Button>
                </TableCell>
                <TableCell>
                  {!customer.disabled && (
                    <NavLink to={`/afnemers/bewerken/${customer.id}`}>
                      <IconButton size="small" disabled={disabled}>
                        <EditIcon color="action" />
                      </IconButton>
                    </NavLink>
                  )}
                  {customer.disabled ? (
                    <IconButton
                      size="small"
                      onClick={() => handleEnable(customer.id)}
                    >
                      <EnableIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => handleDisable(customer.id)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </AsyncTable>
      </Box>
    </>
  );
}
