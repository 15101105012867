import { useEffect } from 'react';

import { Box, Container, Paper } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import { Loader, LogoTitle, PageTitle } from 'components';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';

export interface ChangeEmailParams {
  token: string;
}

export function ChangeEmail() {
  const api = useApi();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { token }: ChangeEmailParams = useParams();

  const activateEmail = async () => {
    const response = await api.post(`/auth/verify-changed-email`, {
      token,
    });
    if (response?.data?.success) {
      dispatch(
        emitFeedback({
          type: FeedbackType.OK,
          message: 'Email veranderd!',
        }),
      );
      history.replace('/inloggen');
    } else {
      dispatch(
        emitFeedback({
          type: FeedbackType.ERROR,
          message: 'Email niet veranderd!',
        }),
      );
      history.replace('/inloggen');
    }
  };

  /**
   * A hook that loads the data on page load
   */
  useEffect(() => {
    activateEmail();
  }, []);

  return (
    <>
      <PageTitle category="Gebruikers" subcategory="Nieuwe email activeren" />
      <Container maxWidth="xs">
        <Paper>
          <Box p={2}>
            <LogoTitle title="Email activeren" />
            <Box my={3} />
            <Loader />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
