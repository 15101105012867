import { ReactElement } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from '@material-ui/core';

import { LogoTitle, PageTitle } from 'components';
import { useInviteUserForm } from 'forms';

/**
 * A view that allows the user to create a new user
 *
 * @returns The `CreateUser` view
 */
export function CreateUser(): ReactElement {
  const { formValues, handleInputChange, handleSubmit, submitting } =
    useInviteUserForm();

  return (
    <>
      <PageTitle
        category="Gebruikers"
        subcategory="Uitnodigen"
        cancelLink="/gebruikers"
      />
      <Container maxWidth="sm" disableGutters>
        <Paper>
          <Box p={2}>
            <LogoTitle title="Gebruikersgegevens invullen" />
            <Box my={2}>
              <Divider />
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Voornaam"
                    name="firstName"
                    onChange={handleInputChange}
                    required
                    type="text"
                    value={formValues.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Tussenvoegsel"
                    name="middleName"
                    onChange={handleInputChange}
                    type="text"
                    value={formValues.middleName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Achternaam"
                    name="lastName"
                    onChange={handleInputChange}
                    required
                    type="text"
                    value={formValues.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Emailadres"
                    name="email"
                    onChange={handleInputChange}
                    required
                    type="email"
                    value={formValues.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Percentage (foto's)"
                    name="photoPercentage"
                    onChange={handleInputChange}
                    required
                    type="number"
                    value={formValues.photoPercentage}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Percentage (video's)"
                    name="videoPercentage"
                    onChange={handleInputChange}
                    required
                    type="number"
                    value={formValues.videoPercentage}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Percentage (BTW)"
                    name="vatPercentage"
                    onChange={handleInputChange}
                    required
                    type="number"
                    value={formValues.vatPercentage}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Percentage (Hulp)"
                    name="sendingHelpPercentage"
                    onChange={handleInputChange}
                    required
                    type="number"
                    value={formValues.sendingHelpPercentage}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Percentage (Bulk)"
                    name="bulkPercentage"
                    onChange={handleInputChange}
                    required
                    type="number"
                    value={formValues.bulkPercentage}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: 100,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.isBusiness}
                        onChange={(e) =>
                          handleInputChange({
                            target: {
                              value: e.target.checked,
                              name: e.target.name,
                            },
                          })
                        }
                        color="primary"
                        name="isBusiness"
                      />
                    }
                    label="Heeft een bedrijf"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.isThirdParty}
                        onChange={(e) =>
                          handleInputChange({
                            target: {
                              value: e.target.checked,
                              name: e.target.name,
                            },
                          })
                        }
                        color="primary"
                        name="isThirdParty"
                      />
                    }
                    label="Is derde partij"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.isEditor}
                        onChange={(e) =>
                          handleInputChange({
                            target: {
                              value: e.target.checked,
                              name: e.target.name,
                            },
                          })
                        }
                        color="primary"
                        name="isEditor"
                      />
                    }
                    label="Is redacteur"
                  />
                </Grid>
                {formValues.isBusiness === true && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Bedrijfsnaam"
                        name="businessName"
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={formValues.businessName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="IBAN"
                        name="iban"
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={formValues.iban}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="BTW Nummer"
                        name="vat"
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={formValues.vat}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="KVK Nummer"
                        name="coc"
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={formValues.coc}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Straat"
                        name="street"
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={formValues.street}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Huisnummer"
                        name="housenumber"
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={formValues.housenumber}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Postcode"
                        name="zipcode"
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={formValues.zipcode}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Plaats"
                        name="place"
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={formValues.place}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={submitting}
                  >
                    {submitting ? 'Laden...' : ' Verstuur uitnodiging'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
