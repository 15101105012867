/**
 * An interface that defines how user session details are stored
 */
export interface UserSession {
  id: number;
  name: string;
  image: string;
}

/**
 * An interface that defines how a token is stored
 */
export interface Token {
  expiry: string;
  access: string;
}

/**
 * An interface that defines how a session is stored
 */
export interface Session {
  token: Token;
  user: UserSession;
}

/**
 * A type that defines the types of pages
 */
export enum Roles {
  USER = 'user',
  ADMIN = 'administrator',
  BUSINESS = 'business',
  THIRDPARTY = 'thirdparty',
}
