import {
  FileCopyRounded as SalesIcon,
  LocalAtmRounded as RatesIcon,
  RecentActorsRounded as CustomerIcon,
  FormatListNumberedRounded as BulkIcon,
  GetAppRounded as ExportIcon,
  PostAddRounded as ManualIcon,
  PeopleRounded as UserIcon,
  ExitToAppRounded as LoginIcon,
  ReceiptRounded as CreditnotaIcon,
  SettingsRounded as SettingsIcon,
  GroupWorkRounded as BundleIcon,
} from '@material-ui/icons';

import { Roles } from 'features';
import { Route, DisplayType } from 'router';
import {
  Bulk as BulkView,
  Bundle as BundleView,
  CreateCustomer as CreateCustomerView,
  CreateSale as CreateSaleView,
  CreateUser as CreateUserView,
  EditSale as EditSaleView,
  EditUser as EditUserView,
  ExportExcel as ExportExcelView,
  Export as ExportView,
  ListCustomers as ListCustomersView,
  ListSales as ListSalesView,
  ListUsers as ListUsersView,
  Login as LoginView,
  ListManual as ListManualView,
  CreateManual as CreateManualView,
  Rates as RatesView,
  ViewExcelExport as ViewExcelExportView,
  EditCustomer as EditCustomerView,
  Account as AccountView,
  ActivateAccount as ActivateAccountView,
  ExportPDF as ExportPDFView,
  Creditnotas as CreditnotasView,
  Settings as SettingsView,
  ResetPassword,
  ChangeEmail,
} from 'views';

/**
 * The routes which are used throughout the application
 */
export const APP_ROUTES: Route[] = [
  // Guest
  {
    access: [],
    display: DisplayType.SIDEBAR,
    fullScreen: true,
    icon: <LoginIcon />,
    name: 'Inloggen',
    path: '/inloggen',
    view: <LoginView />,
  },
  {
    access: [],
    display: DisplayType.HIDDEN,
    fullScreen: true,
    icon: null,
    name: 'Inloggen',
    path: '/activeren/:token',
    view: <ActivateAccountView />,
  },
  {
    access: [],
    display: DisplayType.HIDDEN,
    fullScreen: true,
    icon: null,
    name: 'Inloggen',
    path: '/wachtwoord-wijzigen/:token',
    view: <ResetPassword />,
  },
  {
    access: [],
    display: DisplayType.HIDDEN,
    fullScreen: true,
    icon: null,
    name: 'Inloggen',
    path: '/email-veranderen/:token',
    view: <ChangeEmail />,
  },

  // User
  {
    access: [Roles.USER, Roles.ADMIN, Roles.THIRDPARTY],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Account',
    path: '/account',
    view: <AccountView />,
  },
  {
    access: [Roles.USER, Roles.ADMIN, Roles.THIRDPARTY],
    display: DisplayType.SIDEBAR,
    exact: true,
    icon: <SalesIcon />,
    name: 'Verkopen',
    path: '/',
    view: <ListSalesView />,
  },
  {
    access: [Roles.USER, Roles.ADMIN, Roles.THIRDPARTY],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Verkoop aanmaken',
    path: '/verkopen/nieuw',
    view: <CreateSaleView />,
  },
  {
    access: [Roles.USER, Roles.ADMIN, Roles.THIRDPARTY],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Verkoop bewerken',
    path: '/verkopen/bewerken/:id',
    view: <EditSaleView />,
  },
  {
    access: [Roles.USER, Roles.ADMIN],
    display: DisplayType.SIDEBAR,
    icon: <RatesIcon />,
    name: 'Tarieven',
    path: '/tarieven',
    view: <RatesView />,
  },
  {
    access: [Roles.BUSINESS, Roles.ADMIN, Roles.THIRDPARTY],
    display: DisplayType.SIDEBAR,
    exact: true,
    icon: <CreditnotaIcon />,
    name: "Creditnota's",
    path: '/creditnotas',
    view: <CreditnotasView />,
  },

  // Admin
  {
    access: [Roles.ADMIN],
    display: DisplayType.SIDEBAR,
    exact: true,
    icon: <CustomerIcon />,
    name: 'Afnemers',
    path: '/afnemers',
    view: <ListCustomersView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Afnemer aanmaken',
    path: '/afnemers/nieuw',
    view: <CreateCustomerView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Afnemer Bewerken',
    path: '/afnemers/bewerken/:id',
    view: <EditCustomerView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.SIDEBAR,
    icon: <BulkIcon />,
    name: 'Bulk',
    path: '/bulk',
    view: <BulkView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.SIDEBAR,
    icon: <BundleIcon />,
    name: 'Bundle',
    path: '/bundel',
    view: <BundleView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.SIDEBAR,
    exact: true,
    icon: <ExportIcon />,
    name: 'Export',
    path: '/export',
    view: <ExportView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Export Excel',
    path: '/export/excel',
    view: <ExportExcelView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Export PDF',
    path: '/export/pdf',
    view: <ExportPDFView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Export Excel Overzicht',
    path: '/export/excel/bekijken',
    view: <ViewExcelExportView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.SIDEBAR,
    exact: true,
    icon: <ManualIcon />,
    name: 'Handmatig Bedrag',
    path: '/handmatig',
    view: <ListManualView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Nieuw Handmatig Bedrag',
    path: '/handmatig/nieuw',
    view: <CreateManualView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.SIDEBAR,
    exact: true,
    icon: <UserIcon />,
    name: 'Gebruikers',
    path: '/gebruikers',
    view: <ListUsersView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Gebruiker Uitnodigen',
    path: '/gebruikers/nieuw',
    view: <CreateUserView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.HIDDEN,
    exact: true,
    icon: null,
    name: 'Gebruiker Bewerken',
    path: '/gebruikers/bewerken/:id',
    view: <EditUserView />,
  },
  {
    access: [Roles.ADMIN],
    display: DisplayType.SIDEBAR,
    exact: true,
    icon: <SettingsIcon />,
    name: 'Instellingen',
    path: '/settings',
    view: <SettingsView />,
  },
];

/**
 * A function that retrieves all paths available in the app routes
 * @returns A list of all routes
 */
export const getRoutes = (): Route[] => APP_ROUTES;

/**
 * A function that gets details for a route
 * @param path The pathname to use
 * @returns Details for the function with given pathname
 */
export const getRouteDetails = (path: string): Route | undefined => {
  const [route] = APP_ROUTES.filter(
    (appRoute: Route) => appRoute.path === path,
  );
  return route;
};
