import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `PageTitle` component
 */
export default makeStyles((theme) => ({
  category: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    opacity: 0.9,
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h2.fontSize,
    },
    color: theme.palette.text.disabled,
    fontSize: theme.typography.h1.fontSize,
  },
  subcategory: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    opacity: 0.8,
  },
  subtitle: {
    '&:first-of-type': {
      opacity: 0.8,
    },
    '&:not(:first-of-type)': {
      fontStyle: 'italic',
    },
    opacity: 0.6,
    paddingTop: theme.spacing(1),
  },
}));
