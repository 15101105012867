/**
 * A function that formats the price in the dutch format
 * @param amount The number to format
 * @returns The formatted version of the number
 */
export const formatPrice = (amount: number | string) => {
  const parsed = parseFloat(amount?.toString());

  const isRound = Math.floor(parsed) === parsed;

  let format = `€${new Intl.NumberFormat('de-DE').format(
    parseFloat(parsed.toFixed(2)),
  )}`;

  if (isRound) format += ',-';

  return format;
};

/**
 * A function that populates empty fields
 * @param object The object to populate
 * @returns The populated variant of the provided object
 */
export const populateNull = <T extends Record<string, any>>(object: T): T => {
  const keys = Object.keys(object);

  const mappedObject: any = {};

  keys.forEach((key) => {
    if (object[key] === null) {
      mappedObject[key] = '';
    } else {
      mappedObject[key] = object[key];
    }
  });

  return mappedObject;
};

/**
 * A function that calculates the sale prices
 * @param sale The sale item to use
 */
export const calculateSalePrices = (sale: Record<string, any>) => {
  const basePrices = {
    photo: sale.photoPrice,
    video: sale.videoPrice,
    broadcast: sale.broadcastPrice,
    paper: sale.paperPrice,
    paperSmall: sale.paperSmallPrice,
    max: sale.maximumPrice,
  };

  const getPercent = (base: number, amount: number) => base * (amount / 100);

  return sale.itemSaleUsers.map((itemSaleUser: Record<string, any>) => {
    let maxPrice = null;
    const {
      photoCount,
      videoCount,
      broadcastCount,
      paperCount,
      paperSmallCount,
      salePercent,
    } = itemSaleUser;

    const { photoPercentage, videoPercentage, firstName } = itemSaleUser.user;

    if (
      basePrices.photo * photoCount +
        basePrices.video * videoCount +
        basePrices.broadcast * broadcastCount +
        basePrices.paper * paperCount +
        basePrices.paperSmall * paperSmallCount >=
      basePrices.max
    ) {
      maxPrice = basePrices.max;
    }
    const totals = {
      photo: basePrices.photo * photoCount,
      video: basePrices.video * videoCount,
      broadcast: basePrices.broadcast * broadcastCount,
      paper: basePrices.paper * paperCount,
      paperSmall: basePrices.paperSmall * paperSmallCount,
      max: maxPrice,
    };

    const profits = {
      photo: getPercent(getPercent(totals.photo, photoPercentage), salePercent),
      video: getPercent(getPercent(totals.video, videoPercentage), salePercent),
      broadcast: getPercent(
        getPercent(totals.broadcast, videoPercentage),
        salePercent,
      ),
      paper: getPercent(getPercent(totals.paper, photoPercentage), salePercent),
      paperSmall: getPercent(
        getPercent(totals.paperSmall, photoPercentage),
        salePercent,
      ),
    };

    const calculation = {
      basePrices,
      counts: {
        photo: photoCount,
        video: videoCount,
        broadcast: broadcastCount,
        paper: paperCount,
        paperSmall: paperSmallCount,
      },
      totals: {
        ...totals,
        total:
          totals.photo +
          totals.video +
          totals.broadcast +
          totals.paper +
          totals.paperSmall,
      },
      profits: {
        ...profits,
        total:
          profits.photo +
          profits.video +
          profits.broadcast +
          profits.paper +
          profits.paperSmall,
      },
      percentages: {
        sales: salePercent.toFixed(2).includes('.00')
          ? Math.round(salePercent)
          : salePercent.toFixed(2),
        photo: photoPercentage,
        video: videoPercentage,
      },
    };

    return {
      user: firstName,
      calculation,
    };
  });
};

/**
 * A function that formats the price color
 * @param price The price to use
 */
export const isPriceNegative = (price: number) => price <= 0;
