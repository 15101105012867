/**
 * The width of the drawer when it's expanded (in pixels)
 */
export const DRAWER_WIDTH_EXPANDED: Readonly<number> = 240;

/**
 * The width of the drawer when it's contracted (in pixels)
 */
export const DRAWER_WIDTH_DENSE: Readonly<number> = 56;

/**
 * A list of all available months
 */
export const MONTHS = [
  { id: -1, month: 'Geheel Jaar' },
  { id: 0, month: 'Januari' },
  { id: 1, month: 'Februari' },
  { id: 2, month: 'Maart' },
  { id: 3, month: 'April' },
  { id: 4, month: 'Mei' },
  { id: 5, month: 'Juni' },
  { id: 6, month: 'Juli' },
  { id: 7, month: 'Augustus' },
  { id: 8, month: 'September' },
  { id: 9, month: 'Oktober' },
  { id: 10, month: 'November' },
  { id: 11, month: 'December' },
];
