import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { emitFeedback, FeedbackType } from 'features';
import { RootState, store } from 'store';

/**
 * A function that intercepts requests
 * @param request The request to interact with
 * @returns The request that was interacted with
 */
export const requestInterceptor = (
  request: AxiosRequestConfig,
): AxiosRequestConfig => {
  const { auth }: RootState = store.getState();
  const token = auth?.session?.token?.access;

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
};

/**
 * A function that intercepts responses
 * @param response The response to interact with
 * @returns The response that was interacted with
 */
export const responseInterceptor = <T>(
  response?: any,
  error?: any,
): AxiosResponse<T> => {
  const errorResponse = error?.response;

  let statusType = FeedbackType.INFO;
  let message = 'Onbekend';

  if (response !== undefined) {
    if (response.status === 200) return response;
    if (response.status >= 201 && response.status < 300)
      statusType = FeedbackType.OK;
    if (response.status >= 300 && response.status < 400)
      statusType = FeedbackType.WARN;
    message = response.data.message;
  }
  if (errorResponse !== undefined) {
    if (errorResponse.status >= 400 && errorResponse.status < 600)
      statusType = FeedbackType.ERROR;
    message = `${errorResponse.data.message} (${errorResponse.data.error})`;
  }

  store.dispatch(
    emitFeedback({
      type: statusType,
      message,
    }),
  );

  return response;
};
