import { ReactElement } from 'react';

import { Box, Divider, Menu, MenuItem } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';

import useStyles from './AccountMenu.style';

import { emitFeedback, FeedbackType, logout } from 'features';
import { useAppDispatch } from 'hooks';

/**
 * The property types which are used by the `AccountMenu` component
 */
export interface AccountMenuProps {
  id: string;
  anchor?: Element | null;
  onClose: () => void;
}

/**
 * A component that shows all available options for the account
 *
 * @param id The id of the menu
 * @param anchor The location where the menu should be displayed
 * @param onClose The action to execute on menu close
 *
 * @returns The `AccountMenu` component
 */
export function AccountMenu({
  anchor,
  id,
  onClose,
}: AccountMenuProps): ReactElement {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const history = useHistory();

  /**
   * A function that handles logging the user out
   */
  const handleLogout = () => {
    dispatch(logout());
    dispatch(
      emitFeedback({
        type: FeedbackType.INFO,
        message: 'Succesvol uitgelogd!',
      }),
    );
    history.replace('/inloggen');
    onClose();
  };

  return (
    <Menu anchorEl={anchor} id={id} onClose={onClose} open={Boolean(anchor)}>
      <NavLink to="/account" className={styles.link} onClick={onClose}>
        <MenuItem>Mijn account</MenuItem>
      </NavLink>
      <Box my={1}>
        <Divider />
      </Box>
      <MenuItem onClick={handleLogout}>Uitloggen</MenuItem>
    </Menu>
  );
}
