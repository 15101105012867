import { useEffect, ReactNode, ReactElement } from 'react';

import jwt from 'jsonwebtoken';
import { useHistory, useLocation } from 'react-router-dom';

import {
  emitFeedback,
  FeedbackType,
  hasAccess,
  loadSession,
  logout,
} from 'features';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getRouteDetails } from 'router';

/**
 * The property types which are used by the `Auth` container
 */
export interface AuthProps {
  children: ReactNode;
}

/**
 * A container that manages user authentication
 *
 * @param children The children to wrap with authentication
 *
 * @returns The `Auth` container
 */
export function Auth({ children }: AuthProps): ReactElement {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const { session } = useAppSelector((state) => state.auth);

  /**
   * An effect that redirects a user if they visit a forbidden route
   */
  useEffect(() => {
    if (session) {
      const decodedToken = jwt.decode(session.token.access);
      const currentTime = Date.now();

      const hasExpired = currentTime >= (decodedToken as any).exp * 1000;

      const route = getRouteDetails(pathname);
      if (route) {
        const access = hasAccess(route.access, session.token);
        if (session.user && pathname === '/inloggen') {
          history.replace('/');
        } else if (!access) {
          history.replace('/inloggen');
        } else if (hasExpired) {
          history.replace('/inloggen');
          dispatch(
            emitFeedback({
              type: FeedbackType.INFO,
              message: 'Uw sessie is verlopen, log opnieuw in...',
            }),
          );
          dispatch(logout());
        }
      }
    }
  }, [session, pathname]);

  /**
   * An effect that loads the session on application load
   */
  useEffect(() => {
    dispatch(loadSession());
  }, []);

  return <>{children}</>;
}
