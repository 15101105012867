import {
  combineReducers,
  configureStore,
  EnhancedStore,
} from '@reduxjs/toolkit';

import { appReducer, authReducer, feedbackReducer } from 'features';

/**
 * A combined list of reducers
 */
const reducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  feedback: feedbackReducer,
});

/**
 * A function that configures the app store
 *
 * @param initialState The initial state which should be loaded
 *
 * @returns An instantiation of the application store
 */
function configureAppStore(initialState = {}): EnhancedStore {
  /**
   * Create the application store
   */
  return configureStore({
    reducer: reducers,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });
}

/**
 * An instance of the application store
 */
export const store = configureAppStore();

/**
 * A type that returns a dispatch of store
 */
export type AppDispatch = typeof store.dispatch;

/**
 * A type that retrieves the return type of the root state
 */
export type RootState = ReturnType<typeof reducers>;
