import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { RootState } from 'store';

/**
 * A hook that provides access to the application state
 *
 * @returns The `useAppSelector` hook
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
