import { AxiosResponse } from 'axios';
import jwt from 'jsonwebtoken';

import { Session, Token, UserSession, Roles } from 'features';

/**
 * A function that builds a session
 * @param response The response to build a session from
 * @returns The session built from the response
 */
export const buildSession = (response: AxiosResponse<any>): Session => {
  try {
    const { token, user } = response.data.data;
    const sessionToken: Token = {
      expiry: token.expires_in,
      access: token.access_token,
    };
    const sessionUser: UserSession = {
      id: user.id,
      name: user.firstName,
      image: `https://avatars.dicebear.com/api/jdenticon/${user.firstName.toLowerCase()}.svg`,
    };
    return {
      token: sessionToken,
      user: sessionUser,
    };
  } catch {
    throw new Error('Session could not be built!');
  }
};

/**
 * A function that retrieves the roles from a token
 * @param token The token to get the roles from
 * @returns A list of user roles
 */
export const getRoles = (token: string): string[] => {
  try {
    const decoded: any = jwt.decode(token);
    if (!decoded) throw new Error('Token could not be decoded');
    const { roles } = decoded;
    return roles;
  } catch {
    throw new Error('Token could not be decoded');
  }
};

/**
 * A function that checks if a user has access to something
 * @param userRoles The roles a user has
 * @param requiredPerms The permissions that are required to access
 * @returns If the user has access
 */
export const hasAccess = (requiredPerms: Roles[], token?: Token): boolean => {
  if (!token && requiredPerms.length === 0) return true;
  if (!token && requiredPerms.length > 0) return false;
  if (token) {
    const userRoles = getRoles(token.access);
    return requiredPerms.some((perm) => userRoles.includes(perm));
  }
  return false;
};
