/**
 * The path of the small application logo
 */
export const LOGO_PATH_SMALL: Readonly<string> =
  '/static/logo_heitink_small.png';

/**
 * The path of the large application logo
 */
export const LOGO_PATH_LARGE: Readonly<string> =
  '/static/logo_heitink_large.png';

/**
 * The path of the large Mundaro logo
 */
export const LOGO_PATH_MUNDARO_LARGE: Readonly<string> =
  '/static/logo_mundaro_large.png';
