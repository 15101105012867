import { makeStyles, lighten } from '@material-ui/core';

/**
 * The styles which are used by the `AsyncTable` component
 */
export default makeStyles((theme) => ({
  root: {
    opacity: 0.7,
    fontWeight: 400,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  empty: {
    color: theme.palette.primary.light,
  },
  loading: {
    '& svg': {
      marginRight: theme.spacing(0),
    },
  },
  header: {
    '& th': {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
    },
  },
  body: {
    '& tr': {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}));
