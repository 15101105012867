import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Session } from './types';

/**
 * An object that stores the values for storage keys
 */
const keys = {
  user: '__user__',
  remember: '__remember__',
};

/**
 * The property types which are used by the `Auth` feature state
 */
interface AuthState {
  session?: Session;
  remember: boolean;
}

/**
 * The default property values which are used by the `Auth` feature state
 */
const initialState: AuthState = {
  remember: true,
};

/**
 * A slice which handles all the logic for the `Auth` feature state
 */
const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<Session>) => {
      state.session = action.payload;

      if (state.remember) {
        const compressed = btoa(JSON.stringify(state.session));
        localStorage.setItem(keys.user, compressed);
      }
    },
    logout: (state) => {
      state.session = undefined;
      localStorage.removeItem(keys.user);
    },
    loadSession: (state) => {
      try {
        state.remember = localStorage.getItem(keys.remember) === 'true';

        const localSession = localStorage.getItem(keys.user);
        if (!localSession) throw new Error('Session does not exist...');

        const session = JSON.parse(atob(localSession));
        state.session = session;
      } catch {
        console.error('Session could not be loaded...');
      }
    },
    setRemember: (state, action: PayloadAction<boolean>) => {
      state.remember = action.payload;
      localStorage.setItem(keys.remember, `${action.payload}`);
    },
    updateName: (state, action: PayloadAction<string>) => {
      if (!state.session?.user) return;
      state.session.user.name = action.payload;
    },
  },
});

/**
 * The actions available from within the `Auth` feature state
 */
export const { login, logout, loadSession, setRemember, updateName } =
  slice.actions;

/**
 * The reducer to interact with the `Auth` feature state
 */
export const authReducer = slice.reducer;
