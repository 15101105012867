import { ReactElement } from 'react';

import { Box, Grid, Button, Divider, Typography } from '@material-ui/core';
import { ArrowRight as SubcategoryIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import useStyles from './PageTitle.style';

/**
 * The property types which are used by the `PageTitle` component
 */
export interface PageTitleProps {
  category: string;
  subcategory: string;
  subtitle?: string | string[];
  cancelLink?: string;
}

/**
 * A component that displays the title of a page
 *
 * @param category The category for the page
 * @param subcategory A part of the category which the page is about
 * @param subtitle Some additional explanation
 *
 * @returns The `PageTitle` component
 */
export function PageTitle({
  category,
  subcategory,
  subtitle,
  cancelLink,
}: PageTitleProps): ReactElement {
  const styles = useStyles();
  const history = useHistory();

  /**
   * A function that handles the cancelling for a page
   */
  const handleCancel = () => {
    if (!cancelLink) return;
    history.push(cancelLink);
  };

  return (
    <Box mb={3}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={cancelLink ? 9 : 12}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Typography variant="h3" className={styles.category}>
              {category}
            </Typography>
            <SubcategoryIcon className={styles.icon} />
            <Typography variant="h3" className={styles.subcategory}>
              {subcategory}
            </Typography>
          </Grid>
        </Grid>
        {cancelLink && (
          <Grid item xs={12} sm={3}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Button variant="outlined" onClick={handleCancel}>
                Terug
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {subtitle &&
        (Array.isArray(subtitle) ? (
          subtitle.map((paragraph) => (
            <Typography
              variant="h6"
              className={styles.subtitle}
              key={paragraph}
            >
              {paragraph}
            </Typography>
          ))
        ) : (
          <Typography variant="h6" className={styles.subtitle}>
            {subtitle}
          </Typography>
        ))}
      <Box my={2}>
        <Divider />
      </Box>
    </Box>
  );
}
