/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { Delete as DeletePhotographerIcon } from '@material-ui/icons';
import dayjs from 'dayjs';

import { LogoTitle, PageTitle } from 'components';
import { ReadCustomerDTO, ReadUserItemDTO, SettingsDTO } from 'dto';
import { emitFeedback, FeedbackType, getRoles, Roles } from 'features';
import { useCreateSaleForm } from 'forms';
import { useApi, useAppDispatch, useAppSelector } from 'hooks';

/**
 * A view that allows for creation of a sale
 *
 * @returns The `CreateSale` view
 */
export function CreateSale(): ReactElement {
  const api = useApi();
  const dispatch = useAppDispatch();

  const { session } = useAppSelector((state) => state.auth);

  const {
    addPhotographer,
    deletePhotographer,
    editPhotographer,
    formValues,
    handleInputChange,
    handleScrapeLink,
    handleSubmit,
    scraping,
    submitting,
  } = useCreateSaleForm();

  const [customers, setCustomers] = useState<ReadCustomerDTO[]>([]);
  const [userItems, setUserItems] = useState<ReadUserItemDTO[]>([]);
  const [editorItems, setEditorItems] = useState<ReadUserItemDTO[]>([]);
  const [settings, setSettings] = useState<SettingsDTO>();

  /**
   * A memoized value that checks if the user is an admin
   */
  const isAdmin = useMemo(() => {
    if (session?.token.access === undefined) return false;
    const roles = getRoles(session.token.access);
    return roles.includes(Roles.ADMIN);
  }, [session]);

  /**
   * A function that fetches customer data
   */
  const fetchCustomers = async () => {
    const response = await api.get('/customer/active');
    setCustomers(response.data.data);
  };

  /**
   * A function that fetches editors
   */
  const fetchEditors = async () => {
    const response = await api.get('/user/editors');
    setEditorItems(response.data.data);
  };

  /**
   * A function that fetches settings
   */
  const fetchSettings = async () => {
    const response = await api.get('/settings');
    setSettings(response.data.data);
  };

  /**
   * A function that fetches photographer
   */
  const fetchUserItems = async () => {
    const response = await api.get('/user/for-item');
    const userList = response.data.data;
    setUserItems(
      userList.sort((a: Record<string, any>, b: Record<string, any>) =>
        a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1,
      ),
    );
  };

  /**
   * An effect that fetches data on load
   */
  useEffect(() => {
    fetchCustomers();
    fetchUserItems();
    fetchSettings();
    fetchEditors();
  }, []);

  /**
   * An effect that handles the admin photographer management
   */
  useEffect(() => {
    if (session !== undefined) {
      const userId = session.user.id;
      addPhotographer(userId);
    }
  }, [session]);

  return (
    <>
      <PageTitle category="Verkopen" subcategory="Aanmaken" cancelLink="/" />
      <Container maxWidth="sm" disableGutters>
        <Paper>
          <Box p={2}>
            <LogoTitle title="Verkoopgegevens invullen" />
            <Box my={2}>
              <Divider />
            </Box>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Link naar item"
                    name="link"
                    onChange={handleInputChange}
                    required
                    type="text"
                    value={formValues.link}
                    variant="outlined"
                    onBlur={(e) => {
                      const isDomain = ['www', 'http'].some((prefix) =>
                        e.target.value.includes(prefix),
                      );
                      if (isDomain) {
                        handleScrapeLink();
                      } else {
                        dispatch(
                          emitFeedback({
                            type: FeedbackType.WARN,
                            message: 'Domeinnaam werd niet herkend...',
                          }),
                        );
                      }
                    }}
                    disabled={scraping}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Titel"
                    name="title"
                    onChange={handleInputChange}
                    required
                    type="text"
                    value={formValues.title}
                    variant="outlined"
                    disabled={scraping}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Datum"
                    name="date"
                    onChange={(e) => {
                      const { value } = e.target;
                      const newTime = dayjs(value);
                      const currentTime = dayjs();

                      if (newTime.get('month') === currentTime.get('month')) {
                        handleInputChange(e);
                      } else {
                        dispatch(
                          emitFeedback({
                            type: FeedbackType.WARN,
                            message:
                              'Datum moet binnen de huidige maand zijn...',
                          }),
                        );
                        handleInputChange({
                          target: { value: dayjs().format('YYYY-MM-DD') },
                        });
                      }
                    }}
                    required
                    type="date"
                    value={formValues.date}
                    variant="outlined"
                    disabled={scraping}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    required
                    variant="outlined"
                    fullWidth
                    disabled={scraping || customers.length <= 0}
                  >
                    <InputLabel>Afnemer</InputLabel>
                    <Select
                      label="Afnemer *"
                      name="customerId"
                      onChange={handleInputChange}
                      required
                      value={formValues.customerId}
                    >
                      {formValues.customerId < 0 && (
                        <MenuItem key={-1} value={-1}>
                          <em>Kies een afnemer</em>
                        </MenuItem>
                      )}
                      {customers.map((customer) => (
                        <MenuItem key={customer.id} value={customer.id}>
                          {customer.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Opmerkingen"
                    multiline
                    name="note"
                    onChange={handleInputChange}
                    rows={4}
                    type="text"
                    value={formValues.note}
                    variant="outlined"
                  />
                </Grid>
                {settings?.sendingHelp && (
                  <Grid item xs={12} md={12}>
                    <FormControl
                      required
                      variant="outlined"
                      fullWidth
                      disabled={scraping || editorItems.length <= 0}
                    >
                      <InputLabel>Redacteur</InputLabel>
                      <Select
                        label="Redacteur *"
                        name="editorId"
                        onChange={handleInputChange}
                        required
                        value={formValues.editorId}
                      >
                        {formValues.editorId < 0 && (
                          <MenuItem key={-1} value={-1}>
                            <em>Kies een redacteur</em>
                          </MenuItem>
                        )}
                        {editorItems.map((editor) => (
                          <MenuItem key={editor.id} value={editor.id}>
                            {`${editor.firstName}${
                              editor.middleName ? ` ${editor.middleName}` : ''
                            } ${editor.lastName}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {isAdmin && (
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => addPhotographer()}
                      >
                        Fotograaf toevoegen
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {formValues.photographers.map((photographer, index) => (
                  <Grid key={index} item xs={12}>
                    <Grid container justifyContent="space-between" spacing={2}>
                      <Grid item xs={isAdmin ? 10 : 12} sm={isAdmin ? 11 : 12}>
                        <FormControl variant="outlined" fullWidth required>
                          <InputLabel
                            id={`select-sale-photographer-label-${index}`}
                          >
                            Fotograaf
                          </InputLabel>
                          <Select
                            disabled={!isAdmin}
                            labelId={`select-sale-photographer-label-${index}`}
                            label="Fotograaf * "
                            name="userId"
                            value={formValues.photographers[index].userId}
                            onChange={(event) => editPhotographer(index, event)}
                          >
                            {formValues.photographers[index].userId < 0 && (
                              <MenuItem value={-1}>
                                <em>Kies een fotograaf...</em>
                              </MenuItem>
                            )}
                            {userItems.map((userItem) => (
                              <MenuItem
                                key={userItem.id}
                                value={userItem.id}
                              >{`${userItem.firstName}${
                                userItem.middleName
                                  ? ` ${userItem.middleName}`
                                  : ''
                              } ${userItem.lastName}`}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {isAdmin && (
                        <Grid item xs={2} sm={1}>
                          <IconButton onClick={() => deletePhotographer(index)}>
                            <DeletePhotographerIcon />
                          </IconButton>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={4}>
                            <TextField
                              fullWidth
                              inputProps={{ min: 0, max: 10, step: 1 }}
                              label="Foto's"
                              name="photoCount"
                              onChange={(event) =>
                                editPhotographer(index, event)
                              }
                              required
                              type="number"
                              value={formValues.photographers[index].photoCount}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <TextField
                              fullWidth
                              inputProps={{ min: 0, max: 10, step: 1 }}
                              label="Video's"
                              name="videoCount"
                              onChange={(event) =>
                                editPhotographer(index, event)
                              }
                              required
                              type="number"
                              value={formValues.photographers[index].videoCount}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <TextField
                              fullWidth
                              inputProps={{ min: 0, max: 10, step: 1 }}
                              label="Kranten"
                              name="paperCount"
                              onChange={(event) =>
                                editPhotographer(index, event)
                              }
                              required
                              type="number"
                              value={formValues.photographers[index].paperCount}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              inputProps={{ min: 0, max: 10, step: 1 }}
                              label="Kleine kranten"
                              name="paperSmallCount"
                              onChange={(event) =>
                                editPhotographer(index, event)
                              }
                              required
                              type="number"
                              value={
                                formValues.photographers[index].paperSmallCount
                              }
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              inputProps={{ min: 0, max: 10, step: 1 }}
                              label="Uitzendingen"
                              name="broadcastCount"
                              onChange={(event) =>
                                editPhotographer(index, event)
                              }
                              required
                              type="number"
                              value={
                                formValues.photographers[index].broadcastCount
                              }
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={formValues.photographers.length < 1 || submitting}
                  >
                    {submitting ? 'Laden...' : 'Maak verkoop aan'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
