import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { EditSaleDTO } from 'dto';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';
import { SaleService } from 'services';

/**
 * The initial values that are used by the form
 */
const initialValues: EditSaleDTO = {
  title: '',
  link: '',
  date: dayjs().format('YYYY-MM-DD'),
  note: '',
  customerId: -1,
  editorId: -1,
  photographers: [],
};

/**
 * A hook that makes it easier to interact with the create sale form
 *
 * @returns The `useEditSaleForm` hook
 */
export function useEditSaleForm(saleId: number = -1): {
  // generic form
  formValues: EditSaleDTO;
  handleInputChange: (event: any) => void;
  handleSubmit: (event: any) => void;
  submitting: boolean;
  fetching: boolean;

  // photographer
  addPhotographer: (initialId?: number) => void;
  deletePhotographer: (id: string) => void;
  editPhotographer: (index: number, event: any) => void;

  // scraping
  handleScrapeLink: () => Promise<void>;
  scraping: boolean;
} {
  const api = useApi();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [scraping, setScraping] = useState(false);
  const [formValues, setFormValues] = useState<EditSaleDTO>({
    ...initialValues,
  });

  /**
   * A function thet fetches and sets the user data
   * @param userId The id of the user to fetch
   */
  const fetchSaleData = async (id: number) => {
    setFetching(true);
    const {
      data: { data: response },
    } = await api.get(`/item/${id}`);
    setFetching(false);
    const values: EditSaleDTO = {
      customerId: response.customers.id,
      date: response.date,
      link: response.link,
      note: response.note,
      editorId: response.editor?.id || null,
      photographers: response.itemSaleUsers.map(
        (saleUser: Record<string, any>) => ({
          broadcastCount: saleUser.broadcastCount,
          paperCount: saleUser.paperCount,
          paperSmallCount: saleUser.paperSmallCount,
          photoCount: saleUser.photoCount,
          userId: saleUser.user.id,
          videoCount: saleUser.videoCount,
        }),
      ),
      title: response.title,
    };
    setFormValues(values);
  };

  /**
   * A function that handles the change of input
   * @param event The event to use
   */
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
  };

  /**
   * A function that helps scraping the link and filling in the values
   */
  const handleScrapeLink = async () => {
    if (formValues.link.length < 4) return;
    setScraping(true);
    try {
      const result = await SaleService.scrapeLink(api, formValues.link);

      const updateItems = _.cloneDeep(formValues);

      if (result.data.title) {
        updateItems.title = result.data.title;
      } else {
        updateItems.title = initialValues.title;
      }

      if (result.data.date) {
        updateItems.date = result.data.date;
      } else {
        updateItems.date = initialValues.date;
      }

      if (result.data.customerId) {
        updateItems.customerId = result.data.customerId;
      } else {
        updateItems.customerId = initialValues.customerId;
      }

      setFormValues(updateItems);
    } catch {
      dispatch(
        emitFeedback({
          type: FeedbackType.WARN,
          message: 'Niet alle velden konden automatisch gevuld worden',
        }),
      );
    } finally {
      setScraping(false);
    }
  };

  /**
   * A function that adds an empty photographer field
   */
  const addPhotographer = (initialId = -1): void => {
    const newPhotographer = {
      id: uuid(),
      photoCount: 0,
      videoCount: 0,
      broadcastCount: 0,
      paperSmallCount: 0,
      paperCount: 0,
      userId: initialId,
    };
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      photographers: [...prevFormValues.photographers, newPhotographer],
    }));
  };

  /**
   * A function that deletes a photographer
   * @param id The id of the photographer to delete
   */
  const deletePhotographer = (id: string): void => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      photographers: prevFormValues.photographers.filter(
        (photographer: any) => photographer.id !== id,
      ),
    }));
  };

  /**
   * A function that edits a photographer
   * @param index The index of the item
   * @param event The event to use
   */
  const editPhotographer = (index: number, event: any): void => {
    const { value, name } = event.target;
    const currentPhotographers: any = _.cloneDeep(formValues.photographers);
    currentPhotographers[index][name] = parseInt(value, 10);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      photographers: currentPhotographers,
    }));
  };

  /**
   * A function that submits the form
   * @param event The event to use
   */
  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    setSubmitting(true);
    try {
      const response = await SaleService.editSale(api, saleId, formValues);
      if (response?.success) {
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * An effect which handles the loading of user data
   */
  useEffect(() => {
    fetchSaleData(saleId);
  }, [saleId]);

  return {
    formValues,
    handleInputChange,
    handleSubmit,
    submitting,
    fetching,

    addPhotographer,
    deletePhotographer,
    editPhotographer,

    handleScrapeLink,
    scraping,
  };
}
