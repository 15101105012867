import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import { LogoTitle, PageTitle } from 'components';
import { emitFeedback, FeedbackType } from 'features';
import { useApi, useAppDispatch } from 'hooks';

export interface ActivateAccountParams {
  token: string;
}

export function ActivateAccount() {
  const api = useApi();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { token }: ActivateAccountParams = useParams();

  const [submitting, setSubmitting] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const buttonStatus = useMemo(() => {
    if (!password || !passwordConfirm) {
      return {
        text: 'Wachtwoord invullen',
        disabled: true,
      };
    }
    if (password !== passwordConfirm) {
      return {
        text: 'Wachtwoord ongelijk',
        disabled: true,
      };
    }
    if (submitting) {
      return {
        text: 'Activeren...',
        disabled: true,
      };
    }
    return {
      text: 'Activatie bevestigen',
      disabled: false,
    };
  }, [password, passwordConfirm, submitting]);

  const submitActivation = async () => {
    setSubmitting(true);
    try {
      const response = await api.post(`/auth/verify-account/${token}`, {
        password,
      });
      if (response?.data?.data) {
        dispatch(
          emitFeedback({
            type: FeedbackType.OK,
            message: 'Account succesvol geactiveerd!',
          }),
        );
        history.replace('/inloggen');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const checkIfTokenExist = async () => {
    setSubmitting(true);
    try {
      const response = await api.get(`/auth/verify-account/${token}`);
      if (!response?.data?.data) {
        history.replace('/inloggen');
      }
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * A hook that loads the data on page load
   */
  useEffect(() => {
    checkIfTokenExist();
  }, []);

  return (
    <>
      <PageTitle category="Gebruikers" subcategory="Activeren" />
      <Container maxWidth="xs">
        <Paper>
          <Box p={2}>
            <LogoTitle title="Account activeren" />
            <Box my={3} />
            <form onSubmit={submitActivation}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    variant="outlined"
                    fullWidth
                    label="Wachtwoord"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    variant="outlined"
                    fullWidth
                    label="Wachtwoord bevestigen"
                    required
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={buttonStatus.disabled}
                  >
                    {buttonStatus.text}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
