import { AxiosInstance } from 'axios';

import { ApiResponse } from 'hooks/useApi';

export class AuthService {
  public static async changeEmail(
    api: AxiosInstance,
    oldEmail: string,
    newEmail: string,
    userId: number,
  ): Promise<ApiResponse> {
    const response = await api.post(
      '/auth/change-email',
      JSON.stringify({ oldEmail, newEmail, userId }),
    );
    return response.data;
  }
}
