import { makeStyles } from '@material-ui/core';

/**
 * The styles which are used by the `AccountMenu` component
 */
export default makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  name: {
    textAlign: 'right',
    fontStyle: 'italic',
    padding: theme.spacing(1),
    fontSize: '1rem',
    opacity: 0.8,
  },
}));
